import React, { useState, useEffect } from "react";
import firebase from "../../firebase";
import Sidebar from "../Sidebar/Sidebar";
import styles from "./micuenta.module.scss";
import micuentaImg from "../../assets/img/micuenta.svg";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Login from "./Login";
import Button from "react-bootstrap/Button";

function Alert(props) {
  return <MuiAlert elevation={0} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
const db = firebase.firestore();

const MiCuenta = (props) => {
  const [user, setUser] = useState(false);
  const [utilidad, setUtilidad] = useState(false);
  const [okAlert, setOkAlert] = useState(false);
  const [loginShow, setLoginShow] = useState(false)
  useEffect(() => {
    if (!user) {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          db.collection("users")
            .doc(user.uid)
            .get()
            .then((data) => {
              let usuario = data.data();
              console.log("User", usuario);
              setUser(usuario);
            });
        } else {
          console.log("No user!");
        }
      });
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOkAlert(false);
  };

  const updateUtilidad = () => {
    if (utilidad) {
      db.collection("users")
        .doc(user.id)
        .update({ utilidad: Number(utilidad) })
        .then(setOkAlert(true));
    }
  };

  return (
    <div className="dashboard animated fadeIn fast">
      <Sidebar section={"micuenta"} />

      <div className={styles.micuentacont}>
        <p className={styles.value} style={{ textAlign: "center" }}>
          Mi Cuenta
        </p>
        <div className={styles.imgcont}>
          <img className={styles.imgcont} src={micuentaImg} alt="Contacto" />
        </div>

        {user ? (
          <div>
            <p className={styles.text}>Status :</p>{" "}
            {user.comision ? (
              <p className={styles.activa}>
                {" "}
                <Icon size="sm" icon={faDotCircle} /> Activa
              </p>
            ) : (
              <p className={styles.pending}>
                {" "}
                <Icon size="sm" icon={faDotCircle} /> Pendiente
              </p>
            )}
            {!user.comision ? (
              <p className={styles.textmuted}>
                Habilitaremos tu cuenta en el
                transcurso del dia.
              </p>
            ) : (
              <p className={styles.textmuted}>
                Tu cuenta esta lista para <Link to="/catalogo"> comprar.</Link>
              </p>
            )}
            <br />
            <p className={styles.text}>Negocio :</p>{" "}
            <p className={styles.value}> {user.negocio}</p>
            <br />
            <p className={styles.text}>Usuario :</p>{" "}
            <p className={styles.value}>
              {user.firstName + " " + user.lastName}
            </p>
            <br />
            {user.comision ? (
              <>
                <p className={styles.text}>Descuento :</p>{" "}
                <p className={styles.value}>{user.comision}%</p>
                <br />
              </>
            ) : null}
            <p className={styles.text}>Email :</p>{" "}
            <p className={styles.value}>{user.email}</p>
            <br />
            <p className={styles.text}>Teléfono :</p>{" "}
            <p className={styles.value}>{user.telephone}</p>
            <br />
            <p className={styles.text}>CUIT :</p>{" "}
            <p className={styles.value}>{user.cuit}</p>
            <br />
            <br />
            <p className={styles.value}>¿Qué utilidad deseas tener?</p>
            <p className={styles.textutil}>
              Utilidad :{" "}
              <input
                style={{ width: "120px" }}
                type="number"
                placeholder={user.utilidad}
                onChange={(e) => setUtilidad(e.target.value)}
              />
              {utilidad ? (
                <button
                  className="btn btn-primary btn-sm mx-2"
                  onClick={updateUtilidad}
                >
                  Ok!
                </button>
              ) : null}
            </p>
            <p className={styles.textmuted}>
              Usaremos este porcentaje para calcular el precio de venta estimado
              de cada producto.
            </p>
          </div>
        ) : (
          <Button onClick={()=>setLoginShow(true)}>Iniciar sesión</Button>
        )}
      </div>
      <Login 
      // history={history}
      show={loginShow}
      onHide={() => setLoginShow(false)}
      />
      <Snackbar
        anchorOrigin={{
          marginTop: "65px",
          vertical: "top",
          horizontal: "center",
        }}
        open={okAlert}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Actualización exitosa
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MiCuenta;
