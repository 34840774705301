import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import firebase from "../../firebase";
import { saveJson } from '../../store/actions/saveJson'
import Axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import style from "./products.module.scss";


const AddProducts =({storageJson,theJson})=>{

    const db = firebase.firestore();
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var eljason = storageRef.child('excel/eljason2.json');

    const [data, setData] = useState(storageJson);
    const [loading, setLoading] = useState(true)
    const [selected, setSelected] = useState([])
    const [modified, setModified] = useState(false);
    const [uploadValue, setUploadValue] = useState("");
    const [done, setDone] = useState(false)


    useEffect(()=>{
        //console.log("SJ ",storageJson)
        if(!data){
            eljason.getDownloadURL()
            .then(function(url) {
              //console.log(url)
                Axios({
                  method: 'get',
                  url: url,
                  responseType: 'blob'
                }).then(function(response){
                      const fr = new FileReader();
                      fr.addEventListener("load", e => {
                            //console.log("Set JSON",JSON.parse(fr.result))
                            let prods = JSON.parse(fr.result)
                              theJson(prods)
                              setData(prods)
                              setLoading(false);
                       });
                         fr.readAsText(response.data);
                   })
              }).catch(function(error) {
          switch (error.code) {
            case 'storage/object-not-found':
              break;
            case 'storage/unauthorized':
              break;
            case 'storage/canceled':
              break;
            case 'storage/unknown':
              break;
          }
        });
        }
    },[])

    let results = [{}]
    if(data){results =[
        {ID: (parseInt(storageJson[storageJson.length-1].ID) + 1).toString(),FABRICA: "",CODIGO: "",DESCRIPCION: "",APLICACION: "", EQUIVALENCIAS: "", PL: 0,KEYS: [], U$D: 0 },
        {ID: (parseInt(storageJson[storageJson.length-1].ID) + 2).toString(),FABRICA: "",CODIGO: "",DESCRIPCION: "",APLICACION: "", EQUIVALENCIAS: "", PL: 0,KEYS: [], U$D: 0 } ,
        {ID: (parseInt(storageJson[storageJson.length-1].ID) + 3).toString(),FABRICA: "",CODIGO: "",DESCRIPCION: "",APLICACION: "", EQUIVALENCIAS: "", PL: 0,KEYS: [], U$D: 0 } ,
        {ID: (parseInt(storageJson[storageJson.length-1].ID) + 4).toString(),FABRICA: "",CODIGO: "",DESCRIPCION: "",APLICACION: "", EQUIVALENCIAS: "", PL: 0,KEYS: [], U$D: 0 } ,
        {ID: (parseInt(storageJson[storageJson.length-1].ID) + 5).toString(),FABRICA: "",CODIGO: "",DESCRIPCION: "",APLICACION: "", EQUIVALENCIAS: "", PL: 0,KEYS: [], U$D: 0 } ,
        {ID: (parseInt(storageJson[storageJson.length-1].ID) + 6).toString(),FABRICA: "",CODIGO: "",DESCRIPCION: "",APLICACION: "", EQUIVALENCIAS: "", PL: 0,KEYS: [], U$D: 0 } ,
        {ID: (parseInt(storageJson[storageJson.length-1].ID) + 7).toString(),FABRICA: "",CODIGO: "",DESCRIPCION: "",APLICACION: "", EQUIVALENCIAS: "", PL: 0,KEYS: [], U$D: 0 } ,
        {ID: (parseInt(storageJson[storageJson.length-1].ID) + 8).toString(),FABRICA: "",CODIGO: "",DESCRIPCION: "",APLICACION: "", EQUIVALENCIAS: "", PL: 0,KEYS: [], U$D: 0 } ,
        {ID: (parseInt(storageJson[storageJson.length-1].ID) + 9).toString(),FABRICA: "",CODIGO: "",DESCRIPCION: "",APLICACION: "", EQUIVALENCIAS: "", PL: 0,KEYS: [], U$D: 0 } ,
        {ID: (parseInt(storageJson[storageJson.length-1].ID) + 10).toString(),FABRICA: "",CODIGO: "",DESCRIPCION: "",APLICACION: "", EQUIVALENCIAS: "", PL: 0,KEYS: [], U$D: 0 }
    ]
 }
    let inpCodigo =(cell,row)=>{
        return <input type="text" value={row.CODIGO}></input>
    }
    let inpFabrica =(cell,row)=>{
        return <input type="text" value={row.FABRICA}></input>
    }
    let inpDescripcion =(cell,row)=>{
        return <input type="text" value={row.DESCRIPCION}></input>
    }
    let inpAplicacion =(cell,row)=>{
        return <input type="text" value={row.APLICACION}></input>
    }
    let inpPl =(cell,row)=>{
        return <input type="text" value={row.PL}></input>
    }
    let inpU$D =(cell,row)=>{
        return <input type="text" value={row.U$D}></input>
    }
    let inpEquivalencias =(cell,row)=>{
        return <input type="text" value={row.EQUIVALENCIAS}></input>
    }

    let fontSize = '0.8rem'
    let padding = '1.2rem 0.55rem'
    
    const columns = [
        {
            dataField: "ID",
            text: "ID",
            hidden: false,
            editable: true,
            style: {
                padding,
                fontSize,
                width:'18%',
            }
        },
        {
            dataField: "CODIGO",
            text: "Código",
            hidden: false,
            editable: true,
            formatter: inpCodigo,
            style: {
                padding,
                fontSize,
                width:'18%',
            }

        },
        {
            dataField: "FABRICA",
            text: "Fábrica",
            hidden: false,
            editable: true,
            formatter: inpFabrica,
            style: {
                padding,
                fontSize,
                width:'18%',
            }

        },
        {
            dataField: "DESCRIPCION",
            text: "Descripción",
            hidden: false,
            editable: true,
            formatter: inpDescripcion,
            style: {
                padding,
                fontSize,
                width:'18%',
            }
        },
        {
            dataField: "APLICACION",
            text: "Aplicación",
            hidden: false,
            editable: true,
            formatter: inpAplicacion,
            style: {
                padding,
                fontSize,
                width:'18%',
            }

        },
        {
            dataField: "PL",
            text: "PL",
            hidden: false,
            editable: true,
            formatter: inpPl,
            style: {
                padding,
                fontSize,
                width:'18%',
            }

        },
        // {
        //     dataField: "U$D",
        //     text: "U$D",
        //     hidden: false,
        //     editable: true,
        //     formatter: inpU$D,
        //     style: {
        //         padding,
        //         fontSize,
        //         width:'18%',
        //     }

        // },
        // {
        //     dataField: "EQUIVALENCIAS",
        //     text: "Equivalencias",
        //     hidden: false,
        //     editable: true,
        //     formatter: inpEquivalencias,
        //     style: {
        //         padding,
        //         fontSize,
        //         width:'18%',
        //     }

        // },
    ]
   const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
         setSelected([...selected, row.id])
        } else {
         setSelected(selected.filter(x => x !== row.id))
        }
      }

    // const selectRow = {
    //     mode: 'checkbox',
    //     clickToSelect: true,
    //     clickToEdit: true,
    //     selected: selected,
    //     onSelect: handleOnSelect
    // };

    const actualizar =()=>{
        let actual = storageJson
        results.map(elem=>{
            let existe = false
            if(elem.PL){
                for(let i = 0; i < actual.length ; i++){
                    if(actual[i].ID == elem.ID){
                        elem.PL = Number(elem.PL.replace(",",""))
                        actual[i] = elem
                        existe = true
                        break
                    }
                }  if(!existe){
                    let pre = elem.DESCRIPCION + " " + elem.APLICACION + " " + elem.FABRICA + " " + elem.CODIGO
                    pre = pre.split(" ")
                    let predos = []
                    for(let z = 0; z < pre.length ; z++){
                    if(pre[z] != "los" && pre[z] != "los" && pre[z] != "las" && pre[z] != "con" && pre[z] != "los" && pre[z] != "para" && pre[z].length >=3 && pre[z] != "" ){
                        predos.push(pre[z])
                    }
                    }
                    elem.PL = Number(elem.PL.replace(",",""))
                    elem.KEYS = predos
                    actual.push(elem)
                }    
            }
        })
        console.log("Modificado",actual)
        const stringify = JSON.stringify(actual);
        const blob = new Blob([stringify], { type: "octet/stream" });
        const archivo = new File([blob], "eljason2.json");
        console.log("Archivo", archivo);
        const storageRef = firebase.storage().ref(`excel/${archivo.name}`);
        const task = storageRef.put(archivo);
        task.on(
          "state_changed",
          snapshot => {
            let percentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadValue(percentage);
          },
          error => {
            console.error(error.message);
          },
          () => {
            console.log(task.snapshot.downloadURL);
            setDone(true)
          }
        )
        console.log("FIN!");
    }

    return (
        <div style={{marginTop: '30px'}}>
            {/* <button onClick={()=>actualizar()}>Console log</button> */}
            {modified ? (
                        <>
                            <div className={style.progress}>
                            <ProgressBar animated striped variant="success"  now={uploadValue} max="100" />
                            </div>
                            <button
                            className="btn btn-primary btn-lg mx-1"
                            onClick={async () => {  
                            await actualizar()
                        
                            }}>Actualizar Cambios</button>
                            {done?<p style={{color:'green', margin:'auto 5px'}}> <Icon icon={faCheckDouble} />¡Cambios actualizados correctamente!</p>:null}
                        </>
                        ) : null}
          {data ? (
                <div>
                {/* <p>{selected}</p> */}
                <BootstrapTable
                    keyField='ID' 
                    hover
                    striped
                    data={ results } 
                    columns={ columns } 
                    // selectRow={ selectRow }
                    //   expandRow={ expandRow }
                    cellEdit={ cellEditFactory({
                      mode: 'click',
                      blurToSave: true,
                      onStartEdit: (row, column, rowIndex, columnIndex) => { setModified(true) },
                      beforeSaveCell: (oldValue, newValue, row, column) => { console.log('Before Saving Cell!!'); },
                      afterSaveCell: (oldValue, newValue, row, column) => {  }
                      // beforeSaveCell
                    }) }/>
                </div>
                ):(<p>Loading...</p>)
          }
            
        </div>
            
             
        
    )
}

const mapDispatchToProps = function(dispatch, ownprops) {
    return {
      theJson: eljson => dispatch(saveJson(eljson))
    }
  }

const mapStateToProps = function(state){
    return {
      state,
      productos: state.jsonData.data,
      storageJson: state.storageJson.data
  
    }
  }
  export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AddProducts));