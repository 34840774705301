import React, { useState, useEffect } from "react";
import style from "./products.module.scss";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import firebase from "../../firebase";
import { saveProducts } from "../../store/actions/saveProducts";
import { saveJson } from "../../store/actions/saveJson";
import { addNewItem } from "../Cart/cartActions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Login from "../Users/Login";
import { Link } from "react-router-dom";
import micuentaImg from "../../assets/img/micuenta.svg";

//icons
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faCircleNotch,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";

// Datasheet imports
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import SingleProduct from "../SingleProduct/SingleProduct";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { propTypes } from "react-bootstrap/esm/Image";
import { add } from "dom7";

const Products = ({
  allProds,
  productos,
  addNewItem,
  order,
  json,
  theJson,
  marca
}) => {
  const db = firebase.firestore();
  var storage = firebase.storage();
  var storageRef = storage.ref();
  var eljason = storageRef.child("excel/eljason2.json");

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  // const [json, setJson] = useState("");
  const [results, setResults] = useState(false);
  const [logged, setLogged] = useState(false);
  const [user, setUser] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [inOrder, setInOrder] = useState(order);
  const [selectBrands, setSelectBrands] = useState([]);
  const [filterBrand, setFilterBrand] = useState(marca ? marca : "all");
  const [registerCall, setRegisterCall] = useState(false);
  const all = "all";
  useEffect(() => {
    if (!search) setResults(false);
    if (productos) {
      getBrands();
      setTimeout(function() {
        setLoading(false);
      }, 1500);
    }
    if (!user) {
      // setLoading(true);

      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          setLoading(true);
          setLogged(true);
          db.collection("users")
            .doc(user.uid)
            .get()
            .then(data => {
              let usuario = data.data();
              //console.log("User", usuario);
              setUser(usuario);
              setTimeout(function() {
                setLoading(false);
              }, 2000);
              if (!productos && usuario) getProducts(usuario.comision);
            });
        } else {
          console.log("No user!");
          if (!productos) getProducts();
        }
      });
    }
    if (filterBrand && productos) {
      buscadorMarca(filterBrand);
      // console.log(filterBrand);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, inOrder, productos, filterBrand, user]);

  const getBrands = () => {
    let brands = [];
    productos.map((producto, index) => {
      let already = false;
      let productBrand = producto.fabrica;
      brands.map(brand => {
        if (productBrand === brand) {
          already = true;
        }
      });
      if (!already) brands.push(productBrand);
    });
    // console.log(brands);
    setSelectBrands(brands);
  };

  let precioDeVenta = (cell, row) => {
    if (row.PF) {
      let costo = Number(row.PF.replace(",", ""));
      let up = (costo * user.utilidad) / 100;
      let pdv = costo + up;

      return (
        <p>
          {pdv
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </p>
      );
    }
  };

  let fontSize = "0.8rem";
  let padding = "0.3rem 0.3rem";
  const columns = user.comision
    ? [
        {
          dataField: "id",
          text: "ID ⇅",
          sort: true,
          hidden: true
        },
        {
          dataField: "codigo",
          text: "Código ⇅",
          sort: true,
          style: {
            padding,
            fontSize,
            width: "7%"
          }
        },
        {
          dataField: "fabrica",
          text: "Fábrica ⇅",
          sort: true,
          style: {
            padding,
            fontSize,
            width: "7%"
          }
        },
        {
          dataField: "descripcion",
          text: "Descripción ⇅",
          sort: true,
          style: {
            padding,
            fontSize,
            width: "25%"
          }
        },
        {
          dataField: "aplicacion",
          text: "Aplicación ⇅",
          sort: true,
          style: {
            padding,
            fontSize,
            width: "20%"
          }
        },
        {
          dataField: "",
          text: "Precio de Venta ⇅",
          sort: true,
          formatter: precioDeVenta,
          style: {
            padding,
            fontSize,
            width: "10%"
          }
        },
        {
          dataField: "PL",
          text: "Precio Lista ⇅",
          sort: true,
          style: {
            padding,
            fontSize,
            width: "10%"
          }
        },
        {
          dataField: "PF",
          text: "Costo ⇅",
          sort: true,
          style: {
            padding,
            fontSize,
            width: "10%"
          }
        }
      ]
    : [
        {
          dataField: "id",
          text: "ID ⇅",
          sort: true,
          hidden: true
        },
        {
          dataField: "codigo",
          text: "Código ⇅",
          sort: true,
          style: {
            padding,
            fontSize,
            width: "7%"
          }
        },
        {
          dataField: "fabrica",
          text: "Fábrica ⇅",
          sort: true,
          style: {
            padding,
            fontSize,
            width: "7%"
          }
        },
        {
          dataField: "descripcion",
          text: "Descripción ⇅",
          sort: true,
          style: {
            padding,
            fontSize,
            width: "25%"
          }
        },
        {
          dataField: "aplicacion",
          text: "Aplicación ⇅",
          sort: true,
          style: {
            padding,
            fontSize,
            width: "20%"
          }
        }
      ];
    
  const pdv =(row)=>{
    if (row.PF) {
      let costo = Number(row.PF.replace(",", ""));
      let up = (costo * user.utilidad) / 100;
      let pdv = costo + up;

      return pdv
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    }
  }

  let expandRow = {
    renderer: (row, rowIndex) => (
      <div>
        <SingleProduct
          user={user}
          setModalShow={setModalShow}
          productSolo={row}
          products={json}
          pdv={pdv(row)}
        />
      </div>
    ),
    onlyOneExpanding: true
  };

  const buscador = algo => {
    let arr = algo.trim("");
    arr = arr.split(" ");
    let res = [];
    // console.log("Arr", arr);
    productos.map(prod => {
      let cont = 0;
      for (let i = 0; i < arr.length; i++) {
        if (
          arr[i] == "los" ||
          arr[i] == "los" ||
          arr[i] == "las" ||
          arr[i] == "con" ||
          arr[i] == "los" ||
          arr[i] == "para" ||
          arr[i].length < 3
        ) {
          cont++;
        }
        if (prod.keys.includes(arr[i])) {
          cont++;
          if (arr.length == cont) {
            res.push(prod);
          }
        }
      }
    });
    setResults(res);
  };

  const buscadorMarca = marca => {
    if (!filterBrand || marca === "all") {
      setResults(productos);
    } else {
      let res = [];
      productos.map(prod => {
        if (prod.fabrica === marca) {
          res.push(prod);
        }
      });
      setResults(res);
    }
    // console.log(marca);
  };

  const getProducts = comision => {
    let products = [];
    eljason
      .getDownloadURL()
      .then(function(url) {
        //console.log(url);
        Axios({
          method: "get",
          url: url,
          responseType: "blob"
        }).then(function(response) {
          const fr = new FileReader();
          fr.addEventListener("load", e => {
            // console.log("Set JSON", JSON.parse(fr.result));
            let prods = JSON.parse(fr.result);

            theJson(prods);
            if (comision) {
              prods.map((producto, index) => {
                // console.log(producto,index)
                return products.push({
                  clickToSelect: true,
                  id: producto.ID,
                  codigo: producto.CODIGO,
                  fabrica: producto.FABRICA,
                  keys: producto.KEYS,
                  descripcion: producto.DESCRIPCION,
                  aplicacion: producto.APLICACION,
                  PL: producto.PL.toFixed(2)
                  // .toString().replace(
                  //   /(\d)(?=(\d{3})+(?!\d))/g,
                  //   "$1,"
                  // )
                  ,
                  PF: (producto.PL - (producto.PL * comision) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
                  agregarFueraFormatter: (
                    <Icon
                      color="red"
                      icon={faCartPlus}
                      onClick={() => {
                        console.log(
                          "este es el producto desde afuera de formatter",
                          producto
                        );
                        console.log(
                          "este es la order desde afuera de formatter",
                          order
                        );
                      }}
                    />
                  )
                });
              });
            } else {
              prods.map((producto, index) => {
                return products.push({
                  clickToSelect: true,
                  id: producto.ID,
                  codigo: producto.CODIGO,
                  fabrica: producto.FABRICA,
                  keys: producto.KEYS,
                  descripcion: producto.DESCRIPCION,
                  aplicacion: producto.APLICACION,
                  PL: producto.PL.toString().replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                });
              });
            }
            //console.log(productos);
            allProds(products);
            // setProducts(productos);
            setLoading(false);
          });
          fr.readAsText(response.data);
        });
      })
      .catch(function(error) {
        switch (error.code) {
          case "storage/object-not-found":
            break;
          case "storage/unauthorized":
            break;
          case "storage/canceled":
            break;
          case "storage/unknown":
            break;
        }
      });
  };

  const brands = [
    "bosch",
    "toyota",
    "mopar",
    "chevy",
    "GM",
    "citroen",
    "logitech",
    "lgoo",
    "stanley",
    "3M",
    "luchetti",
    "nike"
  ];

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${
              isSelect ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            {option.text}
          </button>
        );
      })}
    </div>
  );

  const options = {
    sizePerPageRenderer
  };

  return (
    <div className={style.productscontainer}>
      <div className={style.searchcont}>
        <h1 className={style.titulo}>CATÁLOGO</h1>
      </div>

      <div className={style.searchcont}>
        <div className={style.searchbar}>
          <TextField
            className={style.searchinput}
            id="outlined-basic"
            label="Busqueda de producto"
            variant="outlined"
            onChange={e => {
              setSearch(e.target.value.toLowerCase());
            }}
            onKeyPress={event => {
              if (event.key === "Enter") {
                buscador(event.target.value.toLowerCase());
              }
            }}
          />
          <button
            className="btn btn-primary btn-lg"
            onClick={() => buscador(search)}
          >
            Buscar
          </button>
        </div>
        <div className={style.searchbar}>
          <TextField
            className={style.searchinput}
            id="standard-select-brands"
            select
            label="Filtrar por fábrica"
            onChange={e => {
              setFilterBrand(e.target.value.toLowerCase());
            }}
            variant="outlined"
            value={filterBrand}
          >
            <MenuItem label={"Selecciona la marca"} value={all}>
              Todas las fábricas
            </MenuItem>

            {selectBrands[1]
              ? selectBrands.map((brand, index) => (
                  <MenuItem key={index} value={brand}>
                    {brand}
                  </MenuItem>
                ))
              : null}
          </TextField>
          {filterBrand != "all" ? (
            <button
              className="btn btn-primary btn-lg"
              onClick={() => setFilterBrand("all")}
            >
              Todas las fábricas
            </button>
          ) : null}
        </div>
      </div>
      <div className={style.products}>
        <div className={style.datasheetcont}>
          {!logged?
          <p style={{margin:'1rem 2rem'}} >
            <Icon color='lightgray' icon={faExclamationCircle} />
            {' '}
            <Link to="/register">Registrate</Link> para ver precios, agregar al
            carrito y obtener tu{" "}
            <Link
              to="#"
              className="a"
              onClick={() => {
                setRegisterCall(true);
              }}
              >
              descuento personalizado.
            </Link>
          </p>:null
            }
          {loading ? (
            <div className={style.loadingcont}>
              <p className={style.loadingtext}>Cargando productos...</p>
              <Icon
                className={style.loading}
                icon={faCircleNotch}
                size="3x"
                spin
              />
            </div>
          ) : productos ? (
            results ? (
              user.comision ? (
                <div className={style.prodtable}>
                  <BootstrapTable
                    keyField="id"
                    hover
                    striped
                    data={results}
                    columns={columns}
                    pagination={paginationFactory(options)}
                    expandRow={expandRow}
                  />
                </div>
              ) : (
                <div className={style.prodtable}>
                  <BootstrapTable
                    keyField="id"
                    hover
                    striped
                    data={results}
                    columns={columns}
                    pagination={paginationFactory(options)}
                  />
                </div>
              )
            ) : user.comision ? (
              <div className={style.prodtable}>
                <BootstrapTable
                  keyField="id"
                  hover
                  striped
                  data={productos}
                  columns={columns}
                  pagination={paginationFactory(options)}
                  expandRow={expandRow}
                />
              </div>
            ) : (
              <div className={style.prodtable}>
                <BootstrapTable
                  keyField="id"
                  hover
                  striped
                  data={productos}
                  columns={columns}
                  pagination={paginationFactory(options)}
                />
              </div>
            )
          ) : null}
        </div>
      </div>
      <Login show={modalShow} onHide={() => setModalShow(false)} />

      <Modal
      centered
        size="sm"
        show={registerCall}
        onHide={() => setRegisterCall(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <div className={style.callregister}>
          <Modal.Header closeButton>
            <p className={style.titulo}>Descuento personalizado</p>
          </Modal.Header>
          <Modal.Body>
            <div className={style.imgcont}>
              <img src={micuentaImg} alt="Micuenta" />
            </div>
            <p style={{ textAlign: "center", color: "gray" }}>
              Al registrarte te asignaremos un descuento personalizado que
              aplica a todos los productos.
              <br />
              <br />
              También vas a obtener atención personalizada, acceso a descarga de
              archivos y podrás tener un carrito de compras, crear ordenes y
              hacerles seguimiento.
            </p>
            <Link to='/register'>
            <Button className="mx-1" block>
              Crear cuenta
            </Button>
            </Link>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = function(dispatch, ownprops) {
  return {
    allProds: prods => dispatch(saveProducts(prods)),
    addNewItem: (itemId, order) => dispatch(addNewItem(itemId, order)),
    theJson: eljson => dispatch(saveJson(eljson))
  };
};

const mapStateToProps = function(state) {
  return {
    state,
    productos: state.jsonData.data,
    order: state.cart,
    json: state.storageJson.data
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Products)
);
