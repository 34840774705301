import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import style from "./ofertas.module.scss";
import firebase from "../../firebase";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faFileDownload,
  faCircleNotch,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";

const db = firebase.firestore();

const Ofertas = props => {
  const [data, setData] = useState(false);

  useEffect(() => {
    db.collection("ofertas").onSnapshot(function(docs) {
      let array = [];
      docs.forEach(doc => {
        const element = doc.data();
        element.key = doc.id;
        array.push(element);
      });
      setData(array);
    });
  }, []);

  const download = (cell, row) => {
    return <Icon color="#3b70b3" icon={faFileDownload} onClick={() => window.open(row.link)} style={{cursor: "pointer"}}/>;
  };

  const columns = [
    {
      dataField: "",
      text: "Descarga",
      // sort: false,
      hidden: false,
      formatter: download,
      style: {
        textAlign: "center"
      }
    },
    {
      dataField: "description",
      text: "Descripción ⇅",
      sort: true,
      style: {
        width: "75%"
      }
    },
    {
      dataField: "date",
      text: "Subido ⇅",
      sort: true,
      style: {
        width: "15%"
      }
    }
  ];

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${
              isSelect ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            {option.text}
          </button>
        );
      })}
    </div>
  );

  const options = {
    sizePerPageRenderer
  };

  return (
    <div className="dashboard animated fadeIn fast">
      <Sidebar section={"Ofertas"} />
      <div className="dashcontent animated fadeIn fast">
        {data ? (
          <div className={style.filecont}>
            <h3 className={style.text}>Ofertas disponibles</h3>
            <div className={style.prodtable}>
              <BootstrapTable
                keyField="id"
                hover
                striped
                data={data}
                columns={columns}
                //pagination={paginationFactory(options)}
              />
            </div>
          </div>
        ) : (
          <div className={style.filecont} style={{alignItems:'center'}}>
            <h3 className={style.text}>Cargando Ofertas</h3>
            <Icon icon={faCircleNotch} className='mx-3' spin size="4x" color="#3b70b3" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Ofertas;
