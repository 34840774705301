import React, { useState, useEffect } from "react";
import firebase from '../../firebase';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import styles from './products.module.scss'
import SingleOrder from "./SingleOrder";
import {
  tableOptions
} from "../Cart/tableModule";

import paginationFactory from "react-bootstrap-table2-paginator";

const db = firebase.firestore();

const Orders = () => {

  const [orders, setOrders] = useState(false);

    useEffect(()=>{
        db.collection("orders")
        .orderBy("id","desc")
        .onSnapshot(function (docs) {
          let array = [];
          docs.forEach(doc => {
              const element = doc.data();
              element.key = doc.id;
              array.push(element)
          })
          setOrders(array);
      })
    },[])

    const { SearchBar } = Search;
    const columns = [{
        dataField: 'id',
        text: 'Orden⇅',
        editable: false,
        sort: true,
        hidden: false
      }, {
        dataField: 'date',
        text: 'Fecha⇅',
        sort: true,
        editable: false,
        formatter: (date)=>{
            return(
                <p style={{margin:0}}>{date ? new Date(date.seconds*1000).toLocaleString():null}</p>
                )
        }
      }, {
        dataField: 'user.negocio',
        text: 'Negocio⇅',
        sort: true,
        editable: false
      }, {
        dataField: 'total',
        text: 'Total',
        editable: false
      }, {
        dataField: 'status',
        text: 'Estado⇅',
        sort: true,
        editable: true
      },{
        dataField: 'contactPhone',
        text: 'Telefono'
      }
    ];

    const expandRow = {
        renderer: (row, rowIndex) => (
          <div>
            <SingleOrder order={row}/>
          </div>
        ),
        onlyOneExpanding: true,
        showExpandColumn: true
      };

    return (
        <div style={{marginLeft: '30px', marginTop: '30px'}}>
           {orders ? (
               <ToolkitProvider
               keyField="id"
               data={ orders }
               columns={ columns }
               search
             >
               {
                 props => (
                   <div>
                     <SearchBar { ...props.searchProps } />
                     <span className={styles.titulo}>ORDENES</span>
                     {/* <span style={{marginLeft: '50px'}}>Ordenes</span> */}
                     <hr />
                     <BootstrapTable
                       { ...props.baseProps }
                       keyField="id"
                       cellEdit={ cellEditFactory({
                        mode: 'click',
                        afterSaveCell: (oldValue, newValue, row, column) => { 
                          console.log('After Saving Cell!!',newValue,row,column); 
                          db.collection('orders').doc(row.id.toString()).update({status:newValue})
                       }})}
                       expandRow={expandRow}
                                            pagination={paginationFactory(tableOptions)}

                     />
                   </div>
                 )
               }
             </ToolkitProvider>
           ):(
               null
           )}
        </div>
      
    )
};

export default Orders;