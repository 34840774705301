import React, { useEffect, useState } from "react";
import style from "./sidebarAdmin.module.scss";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import {Link, withRouter} from 'react-router-dom'
import firebase from "../../../firebase";

const SidebarAdmin = (props) => {
  
  const [user, setUser] = useState(false);
  const [section, setSection] = useState("orders")
  const db = firebase.firestore();

  useEffect(() => {
    if (!user) {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          db.collection("users")
            .doc(user.uid)
            .get()
            .then(async data => {
              let usuario = data.data();
              if(!usuario.admin){
                props.history.push("/home")
              } else{
                console.log("User", usuario);
                setUser(usuario);
              }
            });
        } else {
          //console.log("No user!");
        }
      });
    }
  });

  const handleClick = e => {
    console.log("props",props)
    if (e == "Logout"){
        //console.log("Bye")
        // firebase.auth().signOut().then(function() {
        //     window.location.reload(false);
            // })
    } else {
        props.setItemOpen(e);
        // console.log(e.key)
    }
   
};
    
  return (
    <div className={style.sidebarcontainer}>

      <div className={style.user}>
        <div className={style.usericoncont}>
          <Icon className={style.usericon} icon={faUser} />
        </div>
        <p className={style.userName+' '+'animated fadeIn fast'}>
          Usuario: <br/>
          {user
            ? user.firstName.charAt(0).toUpperCase() +
              user.firstName.slice(1) +
              " " +
              user.lastName.charAt(0).toUpperCase() +
              user.lastName.slice(1)
            : <Link to='/register'>
            Registrate
            </Link>}
        </p>
      </div>

      <div className={section === "incio" ? style.active : style.nav}>
        <div className={style.line} />
<Link to='/home'>
        <p className={style.text}>Inicio</p>
</Link>
      </div>

      <div className={section === "editar" ? style.active : style.nav}>
        <div className={style.line} />
        <p onClick={()=>{
          setSection("editar")
          handleClick("editar")}}>
        <p className={style.text}>Editar</p>
        </p>
      </div>

      {/* <div className={section === "pedidos" ? style.active : style.nav}>
        <div className={style.line} />
        <Link to='/pedidos'>
        <p className={style.text}>Ordenes</p>
        </Link>
      </div> */}

      <div className={section === "database" ? style.active : style.nav}>
        <div className={style.line} />
        <p onClick={()=>{
          setSection("database")
          handleClick("database")}}>
        <p className={style.text}>Base de Datos</p>
        </p>
      </div>

      <div className={section === "users" ? style.active : style.nav}>
        <div className={style.line} />
        <p onClick={()=>{
          setSection("users")
          handleClick("users")}}>
        <p className={style.text}>Clientes</p>
        </p>
      </div>

      <div className={section === "orders" ? style.active : style.nav}>
        <div className={style.line} />
        <p onClick={()=>{
          setSection("orders")
          handleClick("orders")}}>
        <p className={style.text}>Ordenes</p>
        </p>
      </div>

      <div className={section === "descargas" ? style.active : style.nav}>
        <div className={style.line} />
        <p onClick={()=>{
          setSection("descargas")
          handleClick("descargas")}}>
        <p className={style.text}>Descargas</p>
        </p>
      </div>

      <div className={section === "promos" ? style.active : style.nav}>
        <div className={style.line} />
        <p onClick={()=>{
          setSection("promos")
          handleClick("promos")}}>
        <p className={style.text}>Promos</p>
        </p>
      </div>

      <div className={section === "ofertas" ? style.active : style.nav}>
        <div className={style.line} />
        <p onClick={()=>{
          setSection("ofertas")
          handleClick("ofertas")}}>
        <p className={style.text}>Ofertas</p>
        </p>
      </div>
    </div>
  );
};

export default withRouter(SidebarAdmin);
