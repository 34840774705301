import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Home from "./Home/Home";
import Admin from "./Admin/admin";
import Register from "./Users/Register";
import Query from "./Query/Query";
import NavbarComp from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import style from "./main.module.scss";
import Catalogo from "./Catalogo/Catalogo";
import Administrador from "./Admin/Administrador";
import Cart from "./Cart/Cart";
import Checkout from "./Checkout/Checkout";
import Descargas from './Descargas/Descargas'
import MisOrdenes from "./MisOrdenes/MisOrdenes";
import MiCuenta from "./Users/MiCuenta";
import Ofertas from "./Ofertas/Ofertas";

const history = createBrowserHistory();

export default class Main extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <BrowserRouter history={history}>
        <div id="main" className={style.main+' '+'animated fadeIn fast'}>
          <div>
            <Cart />
            <div className={style.navbarcontainer}>
              <NavbarComp history={history} />
            </div>
          </div>
          <div className={style.contentcontainer+' '+'animated fadeIn fast'}>
            <Switch>
              <Route path="/home" exact component={Home} />
              <Route path="/admin" exact component={Admin} />
              <Route path="/register" exact component={Register} />
              <Route path="/catalogo/:marca" component={Catalogo} />
              <Route path="/catalogo" exact component={Catalogo} />
              <Route path="/micuenta" exact component={MiCuenta} />
              <Route path="/checkout" exact component={Checkout} />
              <Route path="/query" exact component={Query} />
              <Route path="/administrador" exact component={Administrador} />
              <Route path="/descargas" exact component={Descargas} />
              <Route path="/ofertas" exact component={Ofertas} />
              <Route path="/ordenes" exact component={MisOrdenes} />

              <Redirect from="/" to="/home" />
            </Switch>
          </div>
          <div className={style.footercontainer}>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}
