import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import style from "./descargas.module.scss";
import firebase from "../../firebase";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
// import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { Button } from "react-bootstrap";

//icons
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faFileDownload,
  faFileUpload,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
function Alert(props) {
  return <MuiAlert elevation={0} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));
const db = firebase.firestore();

const Descargas = props => {
  const [data, setData] = useState(false);
  const [description, setDescription] = useState(false);
  const [date, setDate] = useState(false);
  const [file, setFile] = useState(false);
  const [added, setAdded] = useState(false);
  const handleClick = () => {
    setAdded(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAdded(false);
  };
  useEffect(() => {
    db.collection("descargas").onSnapshot(function(docs) {
      let array = [];
      docs.forEach(doc => {
        const element = doc.data();
        element.key = doc.id;
        array.push(element);
      });
      setData(array);
    });
  }, []);

  // let data =[{
  //   link: "https://firebasestorage.googleapis.com/v0/b/rapipartesok.appspot.com/o/Descargas%2Fdownload.jpeg?alt=media&token=09631886-4208-4c61-8352-837ee8df6494",
  //   description: "Catalogo de navidad",
  //   date: "12/30/2019"
  // },{
  //   link: "https://firebasestorage.googleapis.com/v0/b/rapipartesok.appspot.com/o/Descargas%2Fdownload.jpeg?alt=media&token=09631886-4208-4c61-8352-837ee8df6494",
  //   description: "Catalogo de navidad",
  //   date: "12/30/2019"
  // },{
  //   link: "https://firebasestorage.googleapis.com/v0/b/rapipartesok.appspot.com/o/Descargas%2Fdownload.jpeg?alt=media&token=09631886-4208-4c61-8352-837ee8df6494",
  //   description: "Catalogo de navidad",
  //   date: "12/30/2019"
  // }]

  const download = (cell, row) => {
    // console.log(row);
    return <Icon icon={faFileDownload} onClick={() => window.open(row.link)} style={{cursor:'pointer'}}/>;
  };

  const eliminar = (cell, row) => {
    // console.log(row);
    return (
      <Icon
        icon={faTrashAlt}
        onClick={() =>
          db
            .collection("descargas")
            .doc(row.id)
            .delete()
        }
      />
    );
  };

  const columns = [
    {
      dataField: "",
      text: "Descarga",
      // sort: false,
      hidden: false,
      formatter: download,
      style: {
        textAlign: "center"
      }
    },
    {
      dataField: "description",
      text: "Descripción",
      // sort: true,
      style: {
        width: "75%"
      }
    },
    {
      dataField: "date",
      text: "Subido",
      // sort: true,
      style: {
        width: "15%"
      }
    },
    {
      dataField: "",
      text: "Eliminar",
      // sort: false,
      hidden: false,
      formatter: eliminar,
      style: {
        textAlign: "center"
      }
    }
  ];

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${
              isSelect ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            {option.text}
          </button>
        );
      })}
    </div>
  );

  const options = {
    sizePerPageRenderer
  };
  async function handleImageUpload(event) {
    console.log("File", event.target.files[0]);
    setFile(event.target.files[0]);
  }
  async function uploadFile() {
    try {
      console.log("FILE", file);
      const storageRef = firebase.storage().ref(`Descargas/${file.name}`);
      const task = storageRef.put(file);
      task.on(
        "state_changed",
        snapshot => {
          let percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // setUploadValue(percentage)
        },
        error => {
          console.error(error.message);
        },
        () => {
          // Upload complete
          task.snapshot.ref.getDownloadURL().then(url => {
            console.log("URL STORAGE", url);
            firebase
              .firestore()
              .collection("descargas")
              .add({
                link: url,
                description: description,
                date: date
              })
              .then(function(docRef) {
                firebase
                  .firestore()
                  .collection("descargas")
                  .doc(docRef.id)
                  .update({ id: docRef.id });
              }).then(setAdded(true));
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="dashboard animated fadeIn fast">
      <div className="dashcontent animated fadeIn fast">
        <div className={style.uploadform}>
          <div className={style.uploadrow}>
            <div>
              <p className={style.text}>Agregar archivo nuevo</p>
              <input
                id="file-upload"
                type="file"
                //   accept="image/*"
                onChange={event => handleImageUpload(event)}
              />
            </div>
            <div>
              <p className={style.text}>Fecha:</p>
              <input
                type="date"
                onChange={e => {
                  setDate(e.target.value);
                }}
              ></input>
            </div>
            <div>
              <p className={style.text}>Descripción:</p>
              <input
                type="text"
                style={{ width: "300px" }}
                onChange={e => {
                  setDescription(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className={style.uploadrow}>
            <Button className="my-3 btn-block" onClick={uploadFile}>
              <Icon icon={faFileUpload} /> Subir
            </Button>
          </div>
        </div>
        <br />
        <br />
        {data ? (
          <div className={style.filecont}>
            <p className={style.text}>Archivos subidos</p>

            <BootstrapTable
              keyField="id"
              hover
              striped
              data={data}
              columns={columns}
              //pagination={paginationFactory(options)}
            />
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ marginTop:"65px", vertical: "top", horizontal: "center" }}
        open={added}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          ¡El archivo fue agregado!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Descargas;
