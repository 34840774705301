import React, { useState } from "react";
import firebase from "../../firebase";
import json from "../Admin/data.json"

const Query = props => {

    const db = firebase.firestore();

    //Estados
    const [products, setProducts] = useState([]);
    const [query, setQuery] = useState([]);
    const [results, setResults] = useState("")
    //Traer los productos
    const getProducts = async()=>{
        try {
          let prods = []
          await db.collection("products")
          .limit(10)
          .get()
          .then(querySnapshot => {
            querySnapshot.docs.map(prod =>{
              prods.push(prod.data())
            })
          })
          await console.log(prods)
          await setProducts(prods)
    
        } catch (error){
          console.log("Error!!!",error)
        }
    }

    //Buscar OR 
        const findUno = async()=>{
            if(query.length){
                try {
                    let info = [] 
                    let filter = []
                    let search = query.split(" ") 
                        search.map(word=>{
                            if( !isNaN(word*2) && word.length ){ return filter.push(word)}
                            if(word != "los" && word != "los" && word != "las" && word != "los" && word != "para" && word.length >=3 && word != "" ){
                                filter.push(word)
                            }
                        })
                
                    if (filter.length){
                        await db.collection("products")
                        .where("KEYS", "array-contains-any", filter )
                        .get()
                        .then(querySnapshot => {
                        querySnapshot.docs.map(res =>{
                            info.push(res.data())
                            console.log("Res.Data", res.data())})
                        })
                        await console.log(info)
                        await setResults(info)
                    }
                } catch (error){
                console.log("Error!!!",error)
                }
            }
        }

        const findDos = ()=>{
            let res = []
            json.map(prod =>{
                console.log(prod["Código"] )
                if(prod["Código"] === parseInt(query)){
                    console.log("Hola", prod["Descripción"] )
                    setResults([prod])
                }
            })
            // if(query.length){
            //     try {
            //         let info = [] 
            //         let filter = []
            //         let search = query.split(" ") 
            //             search.map(word=>{
            //                 if( !isNaN(word*2) && word.length ){ return filter.push(word)}
            //                 if(word != "los" && word != "los" && word != "las" && word != "los" && word != "para" && word.length >=3 && word != "" ){
            //                     filter.push(word)
            //                 }
            //             })
                
            //         if (filter.length){
            //             await db.collection("products")
            //             .where("KEYS", "array-contains-any", [filter[0]] )
            //             .where("KEYS", "array-contains-any", [filter[1]] )
            //             .get()
            //             .then(querySnapshot => {
            //             querySnapshot.docs.map(res =>{
            //                 info.push(res.data())
            //                 console.log("Res.Data", res.data())})
            //             })
            //             await console.log(info)
            //             await setResults(info)
            //         }
            //     } catch (error){
            //     console.log("Error!!!",error)
            //     }
            // }
        }

    // const funcName = async()=>{
    //     try {
    //       let  = []
    //       await db.collection("xxx")
    //       .get()
    //       .then(querySnapshot => {
    //         querySnapshot.docs.map(xxx =>{
    //           xxx.push(xxx.data())
    //         })
    //       })
    //       await console.log(xxx)
    //       await setXxx(xxx)
    
    //     } catch (error){
    //       console.log("Error!!!",error)
    //     }
    // }
      
      
  return (
    <div>
        <h2>Aqui estan todas las Query´s</h2>
        <br/>
        <div>
            <a>Traer 10 Poductos</a>
            <button onClick={()=> getProducts()}>GET</button>
            <div>
            {products[0] ? (
                products.map(prod => {
                return <li key={prod.ID}>{prod.ID + "-" + prod.FABRICA + " // " + prod.APLICACION + " // " + prod.DESCRIPCION + " // " + prod.CODIGO}</li>                                 
                })
            ) : null}
            </div>
        </div>
        <br/>
        <div>
            <a>Busqueda de Producto</a>
            <input type="text" onChange={(e)=>setQuery(e.target.value.toLowerCase())}></input>
            <button onClick={()=> findUno()}>GET</button>
            <div>
            { results[0] ? (
                results.map(res => {
                return <li key={res.ID}>{res.APLICACION + "--" + res.DESCRIPCION}</li>                                 
                })
            ) : null}
            </div>
        </div>
        <br/>
        <div>
            <a>Busqueda de Producto que incluya las palabaras</a>
            <input type="text" onChange={(e)=>setQuery(e.target.value.toLowerCase())}></input>
            <button onClick={()=> findDos()}>GET</button>
            <div>
            { results[0] ? (
                results.map(res => {
                return <li key={res.Código}>{res.Aplicacion + "--" + res.Descripción}</li>                                 
                })
            ) : null}
            </div>
        </div>
        {/* <br/>
        <div>
            <a>XXXXXXX</a>
            <button onClick={()=> xxx()}>GET</button>
            <div>
            { xxx ? (
                xxx.map(xxx => {
                return <li key={xxx.ID}>{}</li>                                 
                })
            ) : null}
            </div>
        </div> */}

    </div>
  );
};

export default Query;