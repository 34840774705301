import React from "react";
import firebase from "../../firebase";
// import data from "../../data.json"
import dataJson from "./data.json"
import { useState } from "react";
import {json2excel, excel2json} from 'js2excel';
import axios from 'axios';
import { useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import AddProducts from './AddProducts';
import Button from "react-bootstrap/Button";

var storage = firebase.storage();
var storageRef = storage.ref();
var eljason = storageRef.child('excel/eljason2.json');

const db = firebase.firestore();

const AdminBack = ({storageJson}) => {

  const [prods,setProds] = useState("")
  const [file,setFile] = useState(false)
  const [uploadValue,setUploadValue] = useState("")
  const [archivo,setArchivo] = useState("")
  const [json , setJson] = useState([])
  const [datos,setDatos] = useState(false)

  const [bulk,setBulk] = useState(false)
  const [show,setShow] = useState(true)
  const [loadUno,setLoadUno] = useState(false)
  const [add, setAdd] = useState(false)
  // const [cols,setCols] = useState("")
  
  useEffect(()=>{
    if(datos)lowerCase(datos)
    //console.log("Looog")
  },[datos,loadUno])


  //Convierte un Excel a JSON , luego ejecuta lowerCase() a traves de useEffect // PASO 1/4 ;
  const uploadData = async()=>{
    console.log("uploadData")
    await excel2json(file, async(data) => {
      // let datos = data["Catálogo Distrimar"]
      await setDatos(data["Catalogo"])
    }, '')
  }

  //UploadData() , convierte todo a lower case // PASO 2/4
  const lowerCase = (params)=>{
    let filtrado = []
    let param = params
    //console.log(param.length)
       for(let i=0 ; i < param.length ;i++){
         //console.log(`param ['${i}']`,param[i])
         let pl = parseFloat(param[i].PL.replace(/[^\d\.\-eE+]/g, ""))
         param[i].PL = pl
                for(let prop in param[i]){
                  if (typeof param[i][prop] === 'string') {
                    param[i][prop] = param[i][prop].toLowerCase();
                  }
                  
                }
          let pre = param[i].DESCRIPCION + " " + param[i].APLICACION + " " + param[i].FABRICA + " " + param[i].CODIGO
          pre = pre.split(" ")
          let predos = []
          for(let j = 0; j < pre.length ; j++){
            if(pre[j] != "los" && pre[j] != "los" && pre[j] != "las" && pre[j] != "con" && pre[j] != "los" && pre[j] != "para" && pre[j].length >=3 && pre[j] != "" ){
              predos.push(pre[j])
          }
          }
          param[i].KEYS = predos
          filtrado.push(param[i])
       }
       uploadFiltrado(filtrado)
  }

  //UploadData() , ... // PASO 3 y 4 , PASO 3 GENERAR JSON FILE Y SUBIRLO A STORAGE , PASO 4 SUBIR EL OBJ A FIRESTORE
  const uploadFiltrado = (filtrado)=>{
    //Aca tengo que subir el filtrado a la Firestore  y tambien generar el File JSON y subirlo a STORAGE
    //console.log("El Filtrado",filtrado)
    //Generar file JSON
    const stringify = JSON.stringify(filtrado);
    const blob = new Blob([stringify],{type: "octet/stream"})
    const archivo = new File([blob], "eljason2.json" )
    //console.log("Archivo",archivo)
    const storageRef = firebase.storage().ref(`excel/${archivo.name}`)
    const task = storageRef.put(archivo)

    task.on('state_changed', (snapshot) => {
            let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            setUploadValue(percentage)
          }, (error) => {
            console.error(error.message)
          }, () => {
            // Upload complete
            setArchivo(task.snapshot.downloadURL)
    })
        //Subir filtrado a Firestore
        // filtrado.map(async(obj)=>{
        //   try {
        //     await db.collection("products")
        //     .doc(obj.ID.toString())
        //     .set(obj)
        //     console.log("Ok" + obj.ID)
        //   } catch (error){
        //   console.log("Error!!!",error)
        //   }
        // })
    //console.log("FIN!")
 }
  
  

  //DESCARGA UN ARCHIVO JSON DEL EXCEL QUE SE SUBE AL INPUT
  const downloadJSONFile = async() => {
    await excel2json(file, async(data) => {
      // let datos = data["Catálogo Distrimar"]
      let datos = data["Rapipartes"]
      // downloadJSONFile(datos)
        //console.log('json', datos)
         try {
          const json = JSON.stringify(datos)
          const element = document.createElement("a");
          const archivo = await new File([json],    
                      {type: "octet/stream"});
          element.href = URL.createObjectURL(archivo);
          element.download = "elJason.json";
          document.body.appendChild(element);
          element.click();
          } catch (error){
          //console.log("Error!!!",error)
          }
    }, '')
  }

  const generateFile = async() => {
    const json = JSON.stringify(dataJson);
    // const archiv = new File(new Blob([json]), "elJason.json",    
    // {type: "octet/stream"})
    const blob = new Blob([json],{type: "octet/stream"})
    const archiv = new File([blob], "eljason.json")
    await setFile(archiv)
  }

  //Si un ID tiene IMG este sera TRUE , solo se cargaran los que si tengan imagen, recibe 2 parametros, uno con el arreglo de ID´s y otro con la condicion de True o False.
  const updateImgBool = async(arreglo,boolean)=>{
     arreglo = [{ID:"4234"},{ID:"12345"},{ID:"7777"}];
     boolean = true
    let objeto = {}

    arreglo.map((articulo)=>{
      objeto[articulo.ID] = boolean
    })
    
    //console.log("Object!!!",objeto)
    // uploadFiltrado(objeto)

    // Subir imagenes a Firestore
    try {
          await db.collection("booleans")
          .doc("img")
          .set(objeto)
          //console.log("Ok" + objeto)
      } catch (error){
        //console.log("Error!!!",error)
    }
  }

  //Trae el ID de los que si tienen foto
  const getBooleans = ()=>{
     db.collection("booleans")
    // .where("bool", "array-contains", "00001" )
    .doc("img")
    .get()
    .then(function(doc) {
      if (doc.exists) {
        let obj = doc.data()
        for (const prop in obj) {
          if(obj[prop]) console.log("ID",prop)
          // console.log(`obj.${prop} = ${obj[prop]}`);
        } 
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
  }).catch(function(error) {
      console.log("Error getting document:", error);
  });
  }

  //Pasa a TRUE el ID al cual se le carga una imagen
  const updateBoolean = ()=>{
    let id = "00975"
    db.collection("booleans")
    .doc("img")
    .get()
    .then(function(doc) {
      if (doc.exists) {
        let obj = doc.data()
        // obj pesa 300kb 
        for (const prop in obj) {
          if(prop == id) { 
            try{
                //console.log("ID",prop)
                let newObj = obj
                newObj[prop]=true
                db.collection("booleans")
                .doc("img")
                .set(newObj)
                //console.log("IMG BOOL TRUE for ID " + id)
              } catch (error){
                console.log("Error!!!",error)
            }
          }
          // console.log(`obj.${prop} = ${obj[prop]}`);
        } 
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
  }).catch(function(error) {
      console.log("Error getting document:", error);
  });

  }

  //Pasa de EXCEL a JSON Object
  const excelJson = async()=>{
    excel2json(file, async(data) => {
      // let datos = data["Catálogo Distrimar"]
      let datos = data["Rapipartes"]
      // downloadJSONFile(datos)
        //console.log('json', datos)
         try {
           setJson(datos)
          } catch (error){
          console.log("Error!!!",error)
          }

    }, '')

  } 

  //Pasar de JSON a Excel y Descargar
  const download = ()=>{
    if(file.name){
      let data = JSON.stringify(file)
      //console.log("Hola!")
      try {
        json2excel({
            data,
            name: 'ElJason',
            formateDate: 'yyyy/mm/dd'
        });
    } catch (e) {
        console.error('export error');
    }} else {
      //console.log("chau!")
      let data = prods
      try {
          json2excel({
              data,
              name: 'Rapipartes',
              formateDate: 'yyyy/mm/dd'
          });
      } catch (e) {
          console.error('export error');
      }
    }   
  }

  //Traer todos los productos 
  const getData = async()=>{
    try {
      //console.log("Data")
      let prods = []
      await db.collection("products")
      .get()
      .then(querySnapshot => {
        querySnapshot.docs.map(prod =>{
          prods.push(prod.data())
        })
      })
      await console.log(prods)
      await setProds(prods)

    } catch (error){
      //console.log("Error!!!",error)
    }
  }
  
  //Lee un File con extension .JSON y lo convierte a JSON Object
  const readJson = ()=> {
    const fr = new FileReader();

    fr.addEventListener("load", e => {
      setJson(JSON.parse(fr.result))
    });

    fr.readAsText(file);
  }

  //Trae el JSON File de Storage y lo convierte en JSON Object
  const getJson = ()=> {
    //console.log("hola")
    eljason.getDownloadURL()
    .then(function(url) {
      //console.log(url)

      axios({
        method: 'get',
        url: url,
        responseType: 'blob'
    }).then(function(response){
      const fr = new FileReader();
      fr.addEventListener("load", e => {
        //console.log("Set JSON",JSON.parse(fr.result))
        setJson(JSON.parse(fr.result))
      });
      fr.readAsText(response.data);
    })
    }).catch(function(error) {
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
    });
  }
 
  //Trae el JSON File de Storage y descarga en Excel
  const storageToExcel = ()=> {
    //console.log("hola")
    eljason.getDownloadURL()
    .then(function(url) {
      //console.log(url)

      axios({
        method: 'get',
        url: url,
        responseType: 'blob'
    }).then(function(response){
       //console.log(response.data)
       const fr = new FileReader();
       fr.addEventListener("load", e => {
        //console.log("Set JSON",JSON.parse(fr.result))
        let data = JSON.parse(fr.result)
      //   setJson(prods)
      //  let data = JSON.stringify(response.data)
      //   console.log(data)
      let excelProm = Promise.resolve(
        json2excel({
          data,
          name: 'Catalogo',
          formateDate: 'yyyy/mm/dd'
      })
      )
        try {
          //aca hay que setear loading true y despuesd en el then setearlo false
         excelProm.then(()=>{
          json2excel({
            data,
            name: 'CopiaCatalogo',
            formateDate: 'yyyy/mm/dd'
        })
           setLoadUno(false)
         })
      } catch (error) {
          console.error('export error');
      }
    })
    fr.readAsText(response.data);

      // const fr = new FileReader();
      // fr.addEventListener("load", e => {
      //   console.log("Set JSON",JSON.parse(fr.result))
      //   setJson(JSON.parse(fr.result))
      // });
      // fr.readAsText(response.data);
    })
    }).catch(function(error) {
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
    });
  }
  
    return (
      <div style={{ display: 'flex'}}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', marginLeft: '30px'}}>
        {show ? ( 
          <div>
            <Button size="lg" block onClick={()=> {
              setShow(false)
              setBulk(true)
            }}>Bulk de Datos</Button>
            {loadUno ? <img src={require('../../images/loadUno.gif')}></img> : (
              <Button size="lg" block onClick={()=> {
                setLoadUno(true)
                storageToExcel()
              }}>Traer Catalogo en Excel</Button>
            )}
            <Button size="lg" block onClick={()=> {
              setShow(false)
              setAdd(true)
            }}>Agregar articulo</Button>
          </div>
        ):(null)}

       
        {bulk ? (
          <div>
            <br />
            <input type="file" onChange={e=>setFile(e.target.files)}/>
            <br />
            {file ? (
              <div>
               <Button size="lg" block onClick={()=> uploadData()}>Subir Data</Button>
               <progress value={uploadValue} max="100">{uploadValue} %</progress>
               <br />
               </div> 
              ) : (null)}
              <Button size="lg" block onClick={()=>{
                setBulk(false)
                setShow(true)
              }}>Volver</Button>
          </div>
          ):(null)
        }

        {add ? (
          <div>
            <AddProducts/>
            <Button size="lg" block onClick={()=>{
                setAdd(false)
                setShow(true)
              }}>Volver</Button>
          </div>
        ):(
          null
        )}
        

        {/* <Button size="lg" block onClick={()=> uploadData()}>Subir Data</Button>
        <progress value={uploadValue} max="100">{uploadValue} %</progress>
        <br /> */}
        {/* <Button size="lg" block onClick={()=> uploadFile()}>Subir Archivo</Button> */}
        {/* <Button size="lg" block onClick={()=> updateBoolean()}>Update Bool Img True</Button>
        <br />
        <Button size="lg" block onClick={()=> getData()}>Get Data</Button>
        <br />
        <Button size="lg" block onClick={()=> getBooleans()}>Get Img Bool que sean True</Button>
        <br />
        <Button size="lg" block onClick={()=> updateImgBool()}>Update Booleano de Imagenes</Button>
        <br />
        <Button size="lg" block onClick={()=> generateFile()}>Generar JSON</Button>
        <br />
        <Button size="lg" block onClick={()=> excelJson()}>Excel a JSON Object</Button>
        <br />
        <Button size="lg" block onClick={()=> download()}>Descargar</Button>
        <br />
        <Button size="lg" block onClick={()=> downloadJSONFile()}>Descargar Excel a JSON File</Button>
        <br />
        <input type="file" onChange={e=>setFile(e.target.files)}/>
        <br />
        <Button size="lg" block onClick={()=> getJson()}>Traer Json File de Storage</Button>
        <br />
        <Button size="lg" block onClick={()=> readJson()}>Set Json</Button>
        <br /> */}
        <div>
          {json[0] ? (
            json.map(elem =>{
              return <div key={elem.ID}>
              <a>ID:<input type="text" id="ID" label="El id" />Descripcion:<input type="text" id="Descripcion" label="Hola"  /></a>
              <Button size="lg" block onClick={()=>console.log(`Cambiar el producto '${elem.ID}'`)}>Update</Button>
              </div>
            })
          ):null}
        </div>
        <div>
          {prods[0] ? (
            <div>
            <h3>Productos traidos de la base de datos:</h3>
            {prods.map(elem =>{
              return <div key={elem.ID}>
              <a>ID:<input type="text" id="ID" label="El id" value={elem.ID}/>Descripcion:<input type="text" id="Descripcion" label="Hola" value={elem.DESCRIPCION} /></a>
              <Button size="lg" block onClick={()=>console.log(`Cambiar el producto '${elem.ID}'`)}>Update</Button>
              </div>
            })}
            </div>
          ):null}
          
        </div>

        </div>
      </div>
    );
  
}


const mapStateToProps = function(state){
  return {
    state,
    productos: state.jsonData.data,
    storageJson: state.storageJson.data

  }
}
export default withRouter(connect(mapStateToProps,null)(AdminBack));


    
  




