import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { TextField } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import firebase from "../../firebase";
import style from "./user.module.scss";
const auth = firebase.auth();
const db = firebase.firestore();

const Login = props => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = async evt => {
    evt.preventDefault();
    try {
      await auth.signInWithEmailAndPassword(email, password).then(() => {
        // console.log(props)
        props.history.push("/catalogo");
        props.onHide();
      });
    } catch (error) {
      let result = {};
      console.log("Error Login", error);
      switch (error.code) {
        case "auth/invalid-email":
          return setError({ target: "email", msg: "Ese email no es válido" });
        case "auth/wrong-password":
          return setError({ target: "pass", msg: "Contraseña incorrecta." });
        case "auth/user-not-found":
          return setError({
            target: "email",
            msg: "Ese correo no está registrado"
          });
        case "auth/too-many-requests":
          return setError({
            target: "all",
            msg: "Intente de nuevo mas tarde."
          });
        default:
          props.history.push("/404");
      }
      return setError(result);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // style={{width:'fit-content', margin:'auto'}}
      className={style.logincont}
    >
      <div className={style.formcontainer}>
        <form className={style.form} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              <p className={style.formtitulo}>INICIÁ SESIÓN</p>
            </Modal.Title>
          </Modal.Header>

          <div className={style.forminput}>
            <TextField
              className={style.input}
              size="small"
              onChange={e => {
                setEmail(e.target.value);
              }}
              type="text"
              name="email"
              id="outlined-basic"
              label="Email"
              variant="outlined"
            />
          </div>
          <div className={style.forminput}>
            <TextField
              className={style.input}
              size="small"
              onChange={e => {
                setPassword(e.target.value);
              }}
              type="password"
              id="outlined-basic"
              name="password"
              label="Contraseña"
              variant="outlined"
            />
          </div>
          {error.target ? (
              <div className={style.errorcontainer}>
                <p className="col textmutedcenter">Error: {error.msg}</p>
              </div>
            ) : null}
            {error.target === "pass" ? (
              <div>
                <p  style={{ textAlign: "center" }} className="textmuted ">
                  ¿Olvidaste tu contraseña?{" "}
                  <br/>
                  <Link
                  className='center'
                   
                    to="#"
                    onClick={() => {
                      firebase
                        .auth()
                        .sendPasswordResetEmail(email)
                        .then(function(user) {
                          alert("Por favor revisa tu email");
                        })
                        .catch(function(e) {
                          console.log(e);
                        });
                    }}
                  >
                    {" "}
                    Reestablecer contraseña
                  </Link>
                </p>
              </div>
            ) : null}
          <div className={style.btn}>
            <button
              
              className="btn btn-block btn-primary "
              type="submit"
            >
              Iniciar sesión
            </button>
            <hr />
            <p className={style.mutedtext}>
              ¿No tenés cuenta? <br />
              <Link onClick={() => props.onHide()} to="/register">
                Registrate
              </Link>
            </p>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default withRouter(Login);
