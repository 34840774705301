import { cartDB } from "./firestoreModule";

const order = data => ({
  type: "UPDATE_ORDER",
  data
});
const items = data => ({
  type: "UPDATE_ITEMS",
  data
});

export const updateOrder = data => dispatch => {
  let newOrder = data;
  if (newOrder.status === "on_cart") {
    newOrder.orderSubtotal = cartDB.getSubtotal(newOrder);
    newOrder.total = cartDB.getTotal(newOrder);
    cartDB.orderUpdate(newOrder);
    dispatch(order(newOrder));
  } else if (newOrder.status === "pending") {
    dispatch(
      order({
        id: 0,
        user: {
          extra:0,
        },
        status: "created",
        deliveryAddres: {
          street: "",
          number: "",
          floorUnit: "",
          city: "",
          province: "",
          postcode: "",
          comments: ""
        },
        itemsList: [],
        contactPhone: "",
        paymentInfo: {},
        orderSubtotal: 0,
        discount: 35,
        total: 0
      })
    );
  }
};

//delete item recibe la orden SIN el item y actualiza
export const deleteItem = incomingOrder => async dispatch => {
  //console.log("esto llega al delete", incomingOrder);
  let newOrder = incomingOrder;
  newOrder.orderSubtotal = cartDB.getSubtotal(newOrder);
  newOrder.total = cartDB.getTotal(newOrder);
  //console.log("esto se dispatcheo desde deleteItem action", newOrder);

  await cartDB.deleteItem(newOrder).then(res => {
    //console.log('dentro de la promesa resuelta', newOrder);
    
    dispatch(order(newOrder));
    dispatch(
      items({
        itemsList: newOrder.itemsList,
        orderSubtotal: newOrder.orderSubtotal,
        total: newOrder.total
      })
    );
  });
};
export const addNewItem = (newProduct, incomingOrder) => async dispatch => {
  //console.log("esto llega a addNewItemAction", incomingOrder);
  let newOrder = incomingOrder;
  let newItemArr = [];
  let already = false;
  newOrder.itemsList.map(item => {
    if (item.product.ID === newProduct.ID) {
      item.quantity += 1;
      item.subtotal = cartDB.getItemSubtotal(item);
      already = true;
    }
    newItemArr.push(item);
  });
  if (already === false) {
    newItemArr.push({
      product: newProduct,
      quantity: 1,
      subtotal: newProduct.PF
    });
  }
  newOrder.itemsList = newItemArr;

  //aca intento hacer que se actualice el subtotal y el total cada vuelta
  newOrder.orderSubtotal = cartDB.getSubtotal(newOrder);
  newOrder.total = cartDB.getTotal(newOrder);

  if (incomingOrder.status === "created") {
    // console.log(
    //   "era una incomingOrder con create y la cambio y la creo",
    //   incomingOrder
    // );
    newOrder.status = "on_cart";
    await cartDB.createOrder(newOrder);
    dispatch(order(newOrder));
    dispatch(
      items({
        itemsList: newOrder.itemsList,
        orderSubtotal: newOrder.orderSubtotal,
        total: newOrder.total
      })
    );
  } else if (incomingOrder.status === "on_cart") {
    // console.log(
    //   "era una incomingOrder con 'on_cart y la updateo",
    //   incomingOrder
    // );
    // console.log("esta es la que dispatcheo desde addnewItem", newOrder);
    cartDB.orderUpdate(newOrder);
    dispatch(order(newOrder));
    dispatch(
      items({
        itemsList: newOrder.itemsList,
        orderSubtotal: newOrder.orderSubtotal,
        total: newOrder.total
      })
    );
  } else {
    return console.log("era una orden pending o diferente");
  }

  // aca va el update
};
