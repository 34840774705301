import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { updateOrder } from "../Cart/cartActions";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import style from "./checkout.module.scss";
import { TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import EmptyCartImg from "../../assets/img/emptycart.svg";
import orderCompletedImg from '../../assets/img/ordercompleted.svg'

//tabla productos, columnas estilos y paginación
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {
  checkoutColumns,
  checkoutOptions,
  tableOptions
} from "../Cart/tableModule";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faMinus } from "@fortawesome/free-solid-svg-icons";
import firebase from "../../firebase";
import Cart from "../Cart/Cart";
const db = firebase.firestore();

const Checkout = props => {
  const [order, setOrder] = useState(props.order);
  const [emptyCart, setEmptyCart] = useState(true);
  const [loading, setLoading] = useState(true);
  const [contactPhone, setContactPhone] = useState(props.order.user.telephone);
  const [street, setStreet] = useState("");
  const [streetNum, setStreetNum] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postCode, setPostCode] = useState("");
  const [floorUnit, setFloorUnit] = useState("");
  const [comments, setComments] = useState("");
  const [envio, setEnvio] = useState(false);
  const [orderCompleted, setOrderCompleted] = useState(false)
  const [cartShow, setCartShow] = React.useState(false);

  let setItems = arr => {
    let itemsDisplay = [];
    arr.map(item => {
      return itemsDisplay.push({
        id: item.product.ID,
        codigo: item.product.CODIGO,
        fabrica: item.product.FABRICA,
        descripcion: item.product.DESCRIPCION,
        aplicacion: item.product.APLICACION,
        precio: item.product.PF,
        cantidad: item.quantity,
        subtotal: item.subtotal
      });
    });

    return itemsDisplay;
  };

  useEffect(() => {
    setOrder(props.order);
    if (props.order.status === "created") setLoading(false);
    //console.log(props.order.itemsList);

    if (order.itemsList.length > 0) {
      setLoading(false);
      setEmptyCart(false);
    }
  });

  useEffect(() => {
    setItems(props.itemsList);
  }, [order]);

  let handleSubmit = e => {
    e.preventDefault();
    let finalOrder = props.order;
    finalOrder.contactPhone = contactPhone ? contactPhone: "";
    finalOrder.deliveryAddres.street = street;
    finalOrder.deliveryAddres.number = streetNum;
    finalOrder.deliveryAddres.city = city;
    finalOrder.deliveryAddres.province = province;
    finalOrder.deliveryAddres.postCode = postCode;
    finalOrder.deliveryAddres.floorUnit = floorUnit;
    finalOrder.deliveryAddres.comments = comments;
    finalOrder.date = new Date();
    finalOrder.status = "pending";
    //console.log("esta orden actualice", finalOrder);
    props.updateOrder(finalOrder);
    db.collection("orders")
      .doc(props.order.id.toString())
      .update(finalOrder)
      .then(data => {
        setOrderCompleted(true)
        //console.log("Orden cambiada")
      })
        .then(()=>{
          fetch('https://us-central1-rapipartesok.cloudfunctions.net/sendOrder', {
        method: 'POST',
        body: JSON.stringify({finalOrder}),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            if(response.ok) {
              //console.log(response)
            } else {
              console.error("Error consultando los datos. \n", response);
              props.history.push("/404");
            }
          })
          .catch((error)=> {
            props.history.push("/404");
          });
      });
        
  };

  return (
    <div className="dashboard animated fadeIn fast">
      <Sidebar section={"checkout"} />
      <div className="dashcontent animated fadeIn fast">
        <div className={style.checkout}>
          {loading ? (
            <div style={{ margin: "auto" }}>
              <Icon icon={faCircleNotch} size="3x" spin />
              <p className={style.text}>Cargando...</p>
            </div>
          ) : emptyCart ? (
            <div className={style.emptycartcont}>
              <div className={style.emptycartimg}>
                <img src={EmptyCartImg} alt="Tu carrito esta vacio" />
              </div>
              <p
                onClick={() => {
                  props.history.push("/catalogo");
                }}
                className={style.mutedtext}
              >
                Tu carrito esta vacio, agregá productos desde el{" "}
                <Link to="/catalogo">catalogo</Link>
              </p>
            </div>
          ) : (
            <div className={style.checkoutcont}>
              <p className={style.text}>
                {" "}
                Orden: <div className={style.value}>{order.id}</div>
              </p>
              <Button style={{marginLeft:'750px',marginTop:'-10px'}} onClick={()=>{setCartShow(true)}}>EDITAR</Button>
              <br />
              <BootstrapTable
                keyField="id"
                hover
                striped
                bordered={false}
                data={setItems(props.itemsList)}
                columns={checkoutColumns}
                // pagination={paginationFactory(tableOptions)}
              />
              <hr />
              <div >
                <div className={style.formtitulo}>Numero de contacto</div>
                <div className={style.section}>
                  <form action="">
                    <div className={style.forminput}>
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Teléfono"
                        variant="outlined"
                        defaultValue={props.order.user.telephone}
                        onChange={e => {
                          setContactPhone(e.target.value);
                        }}
                      />
                    </div>
                    <div className={style.forminput}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={envio}
                            onChange={() => {
                              setEnvio(!envio);
                            }}
                            name="envio"
                            color="primary"
                          />
                        }
                        label="Agregar dirección para envio"
                      />
                    </div>
                  </form>

                  <div className={style.totalcont}>
                    <p className={style.text}>
                      Subtotal:
                      <p className={style.value}>
                        {props.order.orderSubtotal.toFixed(2)}
                      </p>
                    </p>
                    <hr />
                    {props.order.discount > 0 ? (
                      <p>Descuento:{order.discount}%</p>
                    ) : null}

                    <p className={style.text}>
                      Total:
                      <p className={style.total}>{order.total}</p>
                    </p>
                    <hr />
                  </div>
                </div>
              </div>

              {envio ? (
                <>
                  <div className={style.formtitulo}>Agregar dirección</div>
                  <div
                    className={style.formenvio + " " + "animated fadeIn fast"}
                  >
                    <div>
                      <div className={style.forminput}>
                        <TextField
                          size="small"
                          id="outlined-basic"
                          label="Calle"
                          variant="outlined"
                          onChange={e => {
                            setStreet(e.target.value);
                          }}
                        />
                      </div>
                      <div className={style.forminput}>
                        <TextField
                          size="small"
                          id="outlined-basic"
                          label="Altura"
                          variant="outlined"
                          onChange={e => {
                            setStreetNum(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className={style.forminput}>
                        <TextField
                          size="small"
                          id="outlined-basic"
                          label="Piso/Departamento"
                          variant="outlined"
                          onChange={e => {
                            setFloorUnit(e.target.value);
                          }}
                        />
                      </div>
                      <div className={style.forminput}>
                        <TextField
                          size="small"
                          id="outlined-basic"
                          label="Ciudad"
                          variant="outlined"
                          onChange={e => {
                            setCity(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className={style.forminput}>
                        <TextField
                          size="small"
                          id="outlined-basic"
                          label="Provincia"
                          variant="outlined"
                          onChange={e => {
                            setProvince(e.target.value);
                          }}
                        />
                      </div>
                      <div className={style.forminput}>
                        <TextField
                          size="small"
                          id="outlined-basic"
                          label="Codigo Postal"
                          variant="outlined"
                          onChange={e => {
                            setPostCode(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className={style.forminput}>
                        <TextField
                          size="small"
                          id="outlined-basic"
                          label="Comentarios"
                          variant="outlined"
                          onChange={e => {
                            setComments(e.target.value);
                          }}
                          rows={4}
                          
                          multiline
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <Button
                onClick={e => {
                  handleSubmit(e);
                  
                }}
                block
              >
                Realizar pedido
              </Button>
              <hr />
            <p className={style.mutedtext}>
            
              <Link to='/catalogo'>Seguir comprando </Link>
            </p>
            </div>
          )}
        </div>
      </div>
      <Cart
           show={cartShow}
           onHide={() => setCartShow(false)}
      />
      <Modal centered show={orderCompleted} onHide={()=>{
        props.history.push('/catalogo')
        setOrderCompleted(false)}}>
          <div className={style.completedordercont}>
          <Modal.Header closeButton>
            <Modal.Title>
              <p className={style.formtitulo}>¡ORDEN CREADA!</p>
            </Modal.Title>
          </Modal.Header>
        <Modal.Body>
          <img src={orderCompletedImg} alt="Orden completada"/>
          <p className={style.mutedtext} >

          ¡Tu orden fue creada correctamente! <br/>
          Un asesor se contactará contigo para  <br/>
          terminar el proceso de compra. <br/>
          Enviamos una copia de la orden a tu email.
          </p>

        </Modal.Body>
          <Button variant="primary" size='sm' block onClick={()=>{
            props.history.push('/ordenes')
            setOrderCompleted(false)}}>
            Ver mis ordenes
          </Button>
        
            </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = function(dispatch, ownprops) {
  return {
    updateOrder: order => dispatch(updateOrder(order))
  };
};

const mapStateToProps = function(state) {
  return {
    state,
    order: state.cart,
    itemsList: state.cart.itemsList
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
