import React, { useState, useEffect } from "react";
import firebase from '../../firebase';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import styles from './products.module.scss'


const db = firebase.firestore();

const Users = () => {

  const [users, setUsers] = useState(false);

    useEffect(()=>{
        db.collection("users")
        .onSnapshot(function (docs) {
          let array = [];
          docs.forEach(doc => {
              const element = doc.data();
              element.key = doc.id;
              array.push(element)
          })
          setUsers(array);
      })
    },[])

    const { SearchBar } = Search;
    const columns = [{
        dataField: 'id',
        text: 'Id',
        editable: false,
        hidden: true
      }, {
        dataField: 'negocio',
        text: 'Negocio',
        editable: false
      }, {
        dataField: 'firstName',
        text: 'Nombre',
        editable: false
      }, {
        dataField: 'lastName',
        text: 'Apellido ',
        editable: false
      }, {
        dataField: 'email',
        text: 'Email',
        editable: false
      },{
        dataField: 'telephone',
        text: 'Telefono'
      },{
        dataField: 'cuit',
        text: 'C.U.I.T.',
        editable: false
      },{
        dataField: 'comision',
        text: 'Descuento',
        editable: true,
      },
    ];

    return (
        <div style={{marginLeft: '30px', marginTop: '30px'}}>
           {users ? (
               <ToolkitProvider
               keyField="id"
               data={ users }
               columns={ columns }
               search
             >
               {
                 props => (
                   <div>
                     <SearchBar { ...props.searchProps } /> 
                     <span className={styles.titulo}>CLIENTES</span>
                     <hr />
                     <BootstrapTable
                       { ...props.baseProps }
                       cellEdit={ cellEditFactory({
                        mode: 'click',
                        onStartEdit: (row, column, rowIndex, columnIndex) => { console.log('start to edit!!!'); },
                        beforeSaveCell: (oldValue, newValue, row, column) => { console.log('Before Saving Cell!!'); },
                        afterSaveCell: (oldValue, newValue, row, column) => { 
                         // console.log('After Saving Cell!!',newValue,row,column); 
                          db.collection('users').doc(row.id).update({comision:newValue})
                        }
                        // beforeSaveCell
                      }) }
                     />
                   </div>
                 )
               }
             </ToolkitProvider>
           ):(
               null
           )}
        </div>
      
    )
};

export default Users;