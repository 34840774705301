import React, { useState, useEffect } from "react";
import style from "./products.module.scss";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import firebase from "../../firebase";
import { saveProducts } from "../../store/actions/saveProducts";
import { saveJson } from "../../store/actions/saveJson";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ProgressBar from 'react-bootstrap/ProgressBar'
import MenuItem from "@material-ui/core/MenuItem";

//icons
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faCircleNotch, faCheckDouble } from "@fortawesome/free-solid-svg-icons";

// Datasheet imports
import cellEditFactory from "react-bootstrap-table2-editor";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import SingleProduct from "../SingleProduct/SingleProduct";

const Administrador = ({ allProds, productos, json, theJson }) => {
  const db = firebase.firestore();
  var storage = firebase.storage();
  var storageRef = storage.ref();
  var eljason = storageRef.child("excel/eljason2.json");

  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(false);
  const [uploadValue, setUploadValue] = useState("");
  const [modified, setModified] = useState(false);
  const [filterBrand, setFilterBrand] = useState("all");
  const [selectBrands, setSelectBrands] = useState([]);
  const [done, setDone] = useState(false)
  const all = "all";

  useEffect(() => {

    if (!productos) getProducts();
    if (productos) {
      getBrands();
      setTimeout(function(){ setLoading(false) }, 1500)
      
    }
    if (!search) setResults(false);
    if (results) setLoading(false);

    if (filterBrand && productos) {
      buscadorMarca(filterBrand);
      // console.log(filterBrand);
    }
  }, [search, filterBrand, productos]);


  const buscadorMarca = (marca) => {
    if (!filterBrand || marca === "all") {
      setResults(productos);
    } else {
      let res = [];
      productos.map((prod) => {
        if (prod.fabrica === marca) {
          res.push(prod);
        }
      });
      setResults(res);
    }
    // console.log(marca);
  };

  const getBrands = () => {
    let brands = [];
    productos.map((producto, index) => {
      let already = false;
      let productBrand = producto.fabrica;
      brands.map((brand) => {
        if (productBrand === brand) {
          already = true;
        }
      });
      if (!already) brands.push(productBrand);
    });
    // console.log(brands);
    setSelectBrands(brands);
  };

  let fontSize = "0.8rem";
  let padding = "0.3rem 0.3rem";

  const columns = [
    {
      dataField: "id",
      text: "ID ⇅",
      sort: true,
      hidden: false,
      editable: true,
      style: {
        padding,
        fontSize,
        width: "7%"
      }
    },
    {
      dataField: "codigo",
      text: "Código ⇅",
      sort: true,
      editable: true,
      style: {
        padding,
        fontSize,
        width: "7%"
      }
    },
    {
      dataField: "fabrica",
      text: "Fábrica ⇅",
      sort: true,
      editable: true,
      style: {
        padding,
        fontSize,
        width: "7%"
      }
    },
    {
      dataField: "descripcion",
      text: "Descripción ⇅",
      sort: true,
      editable: true,
      style: {
        padding,
        fontSize,
        width: "25%"
      }
    },
    {
      dataField: "aplicacion",
      text: "Aplicación ⇅",
      sort: true,
      editable: true,
      style: {
        padding,
        fontSize,
        width: "20%"
      }
    },
    {
      dataField: "keys",
      text: "keys",
      // filterValue: (cell, row) => console.log(productos.keys[cell]),
      sort: true,
      hidden: true
    },
    {
      dataField: "PL",
      text: "Precio Lista ⇅",
      sort: true,
      editable: true,
      style: {
        padding,
        fontSize,
        width: "10%"
      }
    }
  ];

  const buscador = algo => {
    let arr = algo.trim("");
    arr = arr.split(" ");
    let res = [];
    console.log("Arr", arr);
    productos.map(prod => {
      let cont = 0;
      for (let i = 0; i < arr.length; i++) {
        if (prod.keys.includes(arr[i])) {
          cont++;
          if (arr.length == cont) {
            console.log(cont);
            res.push(prod);
          }
        }
      }
    });
    setResults(res);
  };

  // const expandRow = {
  //   renderer: (row, rowIndex) => (
  //     <div>
  //       <SingleProduct productId={row.id} products={json} />
  //     </div>
  //   ),
  //   onlyOneExpanding: true
  // };

  const getProducts = () => {
    let products = [];
    eljason
      .getDownloadURL()
      .then(function(url) {
        console.log(url);
        Axios({
          method: "get",
          url: url,
          responseType: "blob"
        }).then(function(response) {
          const fr = new FileReader();
          fr.addEventListener("load", e => {
            console.log("Set JSON", JSON.parse(fr.result));
            let prods = JSON.parse(fr.result);
            theJson(prods);
            prods.map((producto, index) => {
              return products.push({
                clickToSelect: true,
                id: producto.ID,
                codigo: producto.CODIGO,
                fabrica: producto.FABRICA,
                keys: producto.KEYS,
                descripcion: producto.DESCRIPCION,
                aplicacion: producto.APLICACION,
                costo: producto.PL + 1,
                PL: producto.PL,
                Add: (
                  <Icon
                    onClick={() => alert("conectar")}
                    key={index}
                    icon={faCartPlus}
                  />
                )
              });
            });
            console.log(productos);
            allProds(products);
            setLoading(false);
          });
          fr.readAsText(response.data);
        });
      })
      .catch(function(error) {
        switch (error.code) {
          case "storage/object-not-found":
            break;
          case "storage/unauthorized":
            break;
          case "storage/canceled":
            break;
          case "storage/unknown":
            break;
        }
      });
  };

  const uploadChanges = () => {
    console.log("json",json)
    if (json.length) {
      console.log("iffff")
      const stringify = JSON.stringify(json);
      const blob = new Blob([stringify], { type: "octet/stream" });
      const archivo = new File([blob], "eljason2.json");
      console.log("Archivo", archivo);
      const storageRef = firebase.storage().ref(`excel/${archivo.name}`);

      const task = storageRef.put(archivo);
      task.on(
        "state_changed",
        snapshot => {
          let percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadValue(percentage);
        },
        error => {
          console.error(error.message);
        },
        () => {
          // Upload complete
          console.log(task.snapshot.downloadURL);
          setDone(true)
        }
      )
      //Subir filtrado a Firestore
      // filtrado.map(async(obj)=>{
      //   try {
      //     await db.collection("products")
      //     .doc(obj.ID.toString())
      //     .set(obj)
      //     console.log("Ok" + obj.ID)
      //   } catch (error){
      //   console.log("Error!!!",error)
      //   }
      // })
      console.log("FIN!");
      
      
    } else {
      console.log("no json");
    }
  };

  const fakeUpload = () => {
    let percentage = 0;
  };
  const brands = [
    "bosch",
    "toyota",
    "mopar",
    "chevy",
    "GM",
    "citroen",
    "logitech",
    "lgoo",
    "stanley",
    "3M",
    "luchetti",
    "nike"
  ];

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${
              isSelect ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            {option.text}
          </button>
        );
      })}
    </div>
  );

  const options = {
    sizePerPageRenderer,
    sizePerPageList: [
      {
        text: "25",
        value: 25
      },
      {
        text: "50",
        value: 50
      },
      {
        text: "100",
        value: 100
      },
      {
        text: "All",
        value: productos.length
      }
    ]
  };

  return (
    <div className={style.productscontainer}>
     
      
      <div className={style.products}>
    
        <h1 className={style.titulo}>CATÁLOGO</h1>
 

      <div className={style.searchcont}>
        <div className={style.searchbar}>
          
          <TextField
            className={style.searchinput}
            id="outlined-basic"
            label="Busqueda de producto"
            variant="outlined"
            onChange={(e) => {
              setSearch(e.target.value.toLowerCase());
            
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                buscador(event.target.value.toLowerCase())
              }
            }}
          />
          <button
            className="btn btn-primary btn-lg mx-1"
            onClick={() => buscador(search)}
          >
            Buscar
          </button>
        </div>
        <div className={style.searchbar}>
          <TextField
            className={style.searchinput}
            id="standard-select-brands"
            select
            label="Filtrar por fábrica"
            onChange={(e) => {
              setFilterBrand(e.target.value.toLowerCase());
            }}
            variant="outlined"
            value={filterBrand}
          >
            <MenuItem label={"Selecciona la marca"} value={all}>
              Todas las fábricas
            </MenuItem>

            {selectBrands[1]
              ? selectBrands.map((brand, index) => (
                  <MenuItem key={index} value={brand}>
                    {brand}
                  </MenuItem>
                ))
              : null}
          </TextField>
          {filterBrand != "all" ? (
            <button
              className="btn btn-primary btn-lg mx-1"
              onClick={() => setFilterBrand("all")}
            >
              Todas las fábricas
            </button>
          ) : null}
        </div>
      </div>

        <div className={style.searchcont}>

        {modified ? (
          <>
            <div className={style.progress}>
            <ProgressBar animated striped variant="success"  now={uploadValue} max="100" />

              
            
            </div>
            <button
            className="btn btn-primary btn-lg mx-1"
            onClick={async () => {  
              await uploadChanges()
         
              }}>Actualizar Cambios</button>
            {done?<p style={{color:'green', margin:'auto 5px'}}> <Icon icon={faCheckDouble} />¡Cambios actualizados correctamente!</p>:null}
          </>
        ) : null}
        </div>
        <div className={style.datasheetcont}>
          {loading ? (
            <div className={style.loadingcont}>
              <p className={style.loadingtext}>Cargando productos...</p>
              <Icon
                className={style.loading}
                icon={faCircleNotch}
                size="3x"
                spin
              />
            </div>
          ) : productos ? (
            results ? (
              <div className={style.prodtable}>
                <div>
                  <BootstrapTable
                    keyField="id"
                    hover
                    striped
                    data={results}
                    columns={columns}
                    pagination={paginationFactory(options)}
                    //   expandRow={ expandRow }
                    cellEdit={cellEditFactory({
                      mode: "click",
                      onStartEdit: (row, column, rowIndex, columnIndex) => {
                        console.log("start to edit!!!");
                      },
                      beforeSaveCell: (oldValue, newValue, row, column) => {
                        console.log("Before Saving Cell!!");
                      },
                      afterSaveCell: (oldValue, newValue, row, column) => {
                        setModified(true);
                        let newlist = productos;
                        let newJson = json;
                        let pre = row.descripcion + " " + row.aplicacion + " " + row.fabrica + " " + row.codigo
                        pre = pre.split(" ")
                        let predos = []
                        for(let j = 0; j < pre.length ; j++){
                          if(pre[j] != "los" && pre[j] != "los" && pre[j] != "las" && pre[j] != "con" && pre[j] != "los" && pre[j] != "para" && pre[j].length >=3 && pre[j] != "" ){
                            predos.push(pre[j])
                          }
                        }
                        console.log("Productos", productos[row.id - 1]);
                        console.log("Row ID", row);
                        console.log("After Saving Cell!!");
                        newlist[row.id - 1] = row;
                        newJson[row.id - 1].APLICACION = row.aplicacion;
                        newJson[row.id - 1].CODIGO = row.codigo;
                        newJson[row.id - 1].DESCRIPCION = row.descripcion;
                        // newJson[row.id -1].EQUIVALENCIAS = row.equivalencias,
                        newJson[row.id - 1].FABRICA = row.fabrica;
                        newJson[row.id - 1].ID = row.id;
                        newJson[row.id - 1].PL = typeof(row.PL) != "number" ? Number(row.PL.replace(",","")) : row.PL;
                        newJson[row.id - 1].KEYS = predos
                        console.log(newJson[row.id - 1])
                        allProds(newlist);
                        theJson(newJson);
                      }
                      // beforeSaveCell
                    })}
                  />
                </div>
              </div>
            ) : (
              <div className={style.prodtable}>
                <div>
                  <BootstrapTable
                    keyField="id"
                    hover
                    striped
                    data={productos}
                    columns={columns}
                    //  defaultSorted={ defaultSorted }
                    pagination={paginationFactory(options)}
                    //   expandRow={ expandRow }
                    cellEdit={cellEditFactory({
                      mode: "click",
                      blurToSave: true,
                      onStartEdit: (row, column, rowIndex, columnIndex) => {
                        console.log("start to edit!!!");
                      },
                      beforeSaveCell: (oldValue, newValue, row, column) => {
                        console.log("Before Saving Cell!!");
                      },
                      afterSaveCell: (oldValue, newValue, row, column) => {
                        setModified(true);
                        let newlist = productos;
                        let newJson = json;
                        console.log("Productos", productos[row.id - 1]);
                        console.log("Row ID", row);
                        console.log("After Saving Cell!!");
                        newlist[row.id - 1] = row;
                        newJson[row.id - 1].APLICACION = row.aplicacion;
                        newJson[row.id - 1].CODIGO = row.codigo;
                        newJson[row.id - 1].DESCRIPCION = row.descripcion;
                        // newJson[row.id -1].EQUIVALENCIAS = row.equivalencias,
                        newJson[row.id - 1].FABRICA = row.fabrica;
                        newJson[row.id - 1].ID = row.id;
                        newJson[row.id - 1].PL = typeof(row.PL) != "number" ? Number(row.PL.replace(",","")) : row.PL;
                        allProds(newlist);
                        theJson(newJson);
                      }
                      // beforeSaveCell
                    })}
                  />
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = function(dispatch, ownprops) {
  return {
    allProds: prods => dispatch(saveProducts(prods)),
    theJson: eljson => dispatch(saveJson(eljson))
  };
};

const mapStateToProps = function(state) {
  return {
    state,
    productos: state.jsonData.data,
    json: state.storageJson.data
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Administrador)
);
