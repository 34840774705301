import React, {useState, useEffect} from "react";
import style from "./navbar.module.scss";
import { Link } from 'react-router-dom'
import { withRouter } from "react-router";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faEnvelope,
  faUserPlus,
  faUserCircle,
  faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button
} from "react-bootstrap";
import logo from "../../assets/img/logo.svg";
import Cart from "../Cart/Cart";
import shoppinCartSVG from "../../assets/img/shopping-cart.svg";
import Login from '../Users/Login'
import Contacto from '../Contacto/Contacto'
import firebase from "../../firebase";

const db = firebase.firestore();


const NavbarComp = props => {
  let nav;
  const [cartShow, setCartShow] = React.useState(false);
  const [loginShow, setLoginShow] = React.useState(false);
  const [contactoShow, setContactoShow] = React.useState(false)
  const [user, setUser] = useState(false);

  useEffect(() => {
    if (!user) {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          db.collection("users")
            .doc(user.uid)
            .get()
            .then(async data => {
              let usuario = data.data();
              //console.log("User", usuario);
              setUser(usuario);
            });
        } else {
          console.log("No user!");
        }
      });
    }
  });

  const history =()=>{
    props.history.push("/catalogo")
  }
  return (
    <div className={style.navbarcontainer}>
      <div className={style.top}>
        <div id="cont-izq" className={style.itemgroup}>
          <a href="tel:+5401135337788" className={style.whitelink}>
            <div className={style.itemtop}>
              <Icon icon={faPhoneAlt} />{" "}
              <div className={style.desktop}> +54 011 3533 7788</div>
            </div>
          </a>

          <a
            href="https://wa.link/xot8p3"
            target="_blank"
            className={style.whitelink}
          >
            <div className={style.itemtop}>
              <Icon icon={faWhatsapp} />
              <div className={style.desktop}>+54 911 2161 1827</div>
            </div>
          </a>

          <div className={style.itemtop}>
            <Icon icon={faEnvelope} />
            <div className={style.desktop}>info.rapipartes@gmail.com</div>
          </div>
        </div>
        <div id="cont-dcha" className={style.itemgroup}>
          {
            !user?(
              <div className={style.itemtop}>
              <Icon icon={faUserPlus} />{" "}
              <Link to="/register" className={style.navbarlinkalt}>
              <div className={style.desktop}> Registrate</div>
              </Link>
            </div>

            ):null
          }
         
         {
            !user?(
              <div
              onClick={e=>setLoginShow(true)}
              className={style.itemtop}>
                <Icon
                icon={faUserCircle} />{" "}
                 <div className={style.desktop}> Iniciar Sesión</div>
  
              </div>

            ):(
              <div
              onClick={ (e)=>{

                firebase.auth().signOut().then(function() {
                  window.location.reload(false);
                }).catch(function(error) {
                  // An error happened.
                  
                });
              }
              }
              className={style.itemtop}>
                <Icon
                icon={faSignOutAlt} />{" "}
                 <div className={style.desktop}> Cerrar sesión</div>
  
              </div>

            )
          }
        
        
        </div>
      </div>
      <div id="linea2">
        <Navbar bg="light" expand="lg">
          <Navbar.Brand>
          <Link to='/home'>
            <div className={style.logo}>
              <img className={style.logo} src={logo} alt="Rapipartes Logo" />
            </div>
          </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav id='navbarglobal' className="mr-auto">
              <Link className={style.navbarlink} to="/catalogo">
                Catálogo
              </Link>
              
              <Link className={style.navbarlink} to="/home#contacto">
                Nosotros
              </Link>
              <Link onClick={(e)=>{setContactoShow(true)}}>
                Contacto
              </Link>
              <div className={style.iconcontainer}>
                <div 
                onClick={() => setCartShow(true)}
                className={style.icon} >

                <img src={shoppinCartSVG} alt="carrito de compras" />
                </div>
              </div>
             
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <Cart
           show={cartShow}
           onHide={() => setCartShow(false)}
           
       
      />
      <Login 
      // history={history}
      show={loginShow}
      onHide={() => setLoginShow(false)}
      />
            <Contacto 
      show={contactoShow}
      onHide={() => setContactoShow(false)}
      />
    </div>
  );
};

export default NavbarComp;
