import React, { useEffect, useState } from "react";
import SidebarAdmin from "./SidebarAdmin/SidebarAdmin";
import Administrador from "./Administrador";
import AdminBack from "./AdminBack";
import Users from "./Users";
import Orders from "./Orders";
import firebase from "../../firebase";
import Descargas from "./Descargas";
import Promos from "./Promos";
import Ofertas from "./Ofertas";


const db = firebase.firestore()

const Admin = () => {

    const [itemOpen, setItemOpen] = useState("orders");
    const [user, setUser] = useState(false)

    useEffect(()=>{
        if (!user) {
            firebase.auth().onAuthStateChanged(function(user) {
              if (user) {
                db.collection("users")
                  .doc(user.uid)
                  .get()
                  .then(async data => {
                    let usuario = data.data();
                    if(usuario){
                        if(usuario.admin){
                            setUser(usuario);
                        }
                      }
                    });
                }
            });
          }
    },[])

    return (
        <div >
            {user ? (
                <div className='dashboard'>
                    <SidebarAdmin setItemOpen={setItemOpen}/>
                    <div className='dashcontent'>
                        {itemOpen == "editar" ? <Administrador/> : null}
                        {itemOpen == "database" ? <AdminBack/> : null}
                        {itemOpen == "users" ? <Users/> : null}
                        {itemOpen == "orders" ? <Orders/> : null}
                        {itemOpen == "descargas" ? <Descargas/> : null}
                        {itemOpen == "promos" ? <Promos/> : null}
                        {itemOpen == "ofertas" ? <Ofertas/> : null}
                    </div>
                </div>
            ):(<p>Forbidden</p>)}
        </div>
    )
};

export default Admin;
