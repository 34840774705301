import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { updateOrder } from "../Cart/cartActions";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import style from "./misordenes.module.scss";
import { TextField, FormControlLabel, Checkbox } from "@material-ui/core";

import logoText from "../../assets/img/logotexto.svg";
import EmptyCartImg from "../../assets/img/emptycart.svg";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faMinus } from "@fortawesome/free-solid-svg-icons";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";

//tabla productos, columnas estilos y paginación
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {
  checkoutColumns,
  checkoutOptions,
  tableOptions
} from "../Cart/tableModule";

import firebase from "../../firebase";
const db = firebase.firestore();

const MisOrdenes = props => {
  const [myOrders, setMyOrders] = useState(false);
  const [user, setUser] = useState(false);
  const [order, setOrder] = useState(false);
  const [showOrder, setShowOrder] = useState(false);

  let setItems = arr => {
    let itemsDisplay = [];
    arr.map(item => {
      return itemsDisplay.push({
        id: item.product.ID,
        codigo: item.product.CODIGO,
        fabrica: item.product.FABRICA,
        descripcion: item.product.DESCRIPCION,
        aplicacion: item.product.APLICACION,
        precio: item.product.PF,
        cantidad: item.quantity,
        subtotal: item.subtotal
      });
    });

    return itemsDisplay;
  };

  useEffect(() => {
    let myOrdersArr = [];
    if (!user) {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          db.collection("users")
            .doc(user.uid)
            .get()
            .then(async data => {
              let usuario = data.data();
              //console.log("User", usuario);
              if(usuario){

                setUser(usuario);
                await db
                  .collection("orders")
                  .where("user.id", "==", usuario.id)
                  .orderBy("id","asc")
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.docs.map(res => {
                      if (
                        res.data().status === "created" ||
                        res.data().status === "on_cart" ||
                        res.data().id === 0
                      ) {
                        return;
                      } else {
                        myOrdersArr.unshift(res.data());
                      }
                    });
                  })
                  .then(() => {
                    //console.log(myOrdersArr)
                    setMyOrders(myOrdersArr);
                  });
              }
            });
        }
      });
    }
  },[]);

  return (
    <>
      <div className="dashboard animated fadeIn fast">
        <Sidebar section={"pedidos"} />
        <div className="dashcontent animated fadeIn fast">
          {myOrders.length ? (
            <div className={style.myordercont}>
              {myOrders.length ? <h1 className={style.titulo}>Mis Ordenes</h1> : null}

              {myOrders.length
                ? myOrders.map((order, index) => {
                    // console.log(myOrders)
                    // console.log(order.date);
                    // console.log("AQUI ESTA LA FECHA", order.date);

                    return (
                      <div
                        onClick={() => {
                          setOrder(order);
                          setShowOrder(true);
                        }}
                        index={index}
                        className={style.orderline}
                      >
                        <p className={style.text}>
                          {" "}
                          Orden: <div className={style.value}>{order.id}</div>
                        </p>
                        <p className={style.date}>
                          {" "}
                          {order.date
                            ? order.date
                                .toDate()
                                .toDateString()
                                .slice(3, 15)
                            : null}
                        </p>

                        <p className={style.textgreen}>
                          {" "}
                          <Icon size="sm" icon={faDotCircle} /> {order.status}
                        </p>
                        <p className={style.totalcont}>
                          {" "}
                          Total:{" "}
                          <div className={style.total}>${order.total}</div>
                        </p>
                      </div>
                    );
                  })
                : null}
            </div>
          ) : (
            <div className={style.emptycartcont}>
              <div className={style.emptycartimg}>
                <img src={EmptyCartImg} alt="Tu carrito esta vacio" />
              </div>
              <p
                onClick={() => {
                  props.history.push("/catalogo");
                }}
                className={style.mutedtext}
              >
                Parece que no tienes ordenes todavia, <br />
                empezá a armar tu pedido desde el{" "}
                <Link to="/catalogo">catalogo</Link>
              </p>
            </div>
          )}
        </div>
      </div>
      {order.itemsList ? (
        <Modal
          centered
          show={showOrder}
          onHide={() => {
            setShowOrder(false);
          }}
          size="lg"
        >
          <div className={style.completedordercont}>
            <Modal.Body>
              <div className={style.checkoutcont}>
                <div className={style.section}>
                  <div>
                    <p className={style.text}>
                      {" "}
                      Orden: <div className={style.value}>{order.id}</div>
                    </p>
                    <p className={style.textgreen}>
                      {" "}
                      <Icon size="sm" icon={faDotCircle} /> {order.status}
                    </p>
                  </div>

                  <img
                    style={{ height: "25px" }}
                    src={logoText}
                    alt="rapipartes"
                  />
                </div>
                <br />
                <BootstrapTable
                  keyField="id"
                  hover
                  striped
                  bordered={false}
                  data={setItems(order.itemsList)}
                  columns={checkoutColumns}
                  //pagination={paginationFactory(tableOptions)}
                />
                <hr />
                <div className={style.section}>
                  <div className={style.contactinfo}>
                    <div className={style.formtitulo}>
                      Numero de contacto: <strong> {order.contactPhone}</strong>
                    </div>
                    {order.deliveryAddres.street ? (
                      <>
                        <div className={style.formtitulo}>
                          Dirección de entrega:
                        </div>
                        <div>
                          {order.deliveryAddres.street},{" "}
                          {order.deliveryAddres.number},{" "}
                          {order.deliveryAddres.floorUnit}
                        </div>

                        <div>
                          {order.deliveryAddres.city},{" "}
                          {order.deliveryAddres.province},{" "}
                          {order.deliveryAddres.postCode}
                        </div>

                        <div>Comentarios: {order.deliveryAddres.comments}</div>
                      </>
                    ) : null}
                  </div>
                  <div className={style.totalcont}>
                    <p className={style.text}>
                      Subtotal:
                      <p className={style.value}>
                        {order.orderSubtotal.toFixed(2)}
                      </p>
                    </p>
                    <hr />
                    {order.discount > 0 ? (
                      <p>Descuento:{order.discount}%</p>
                    ) : null}

                    <p className={style.text}>
                      Total:
                      <p className={style.total}>{order.total}</p>
                    </p>
                  </div>
                </div>
                <Button
                  variant="primary"
                  size="sm"
                  block
                  onClick={() => {
                    setShowOrder(false);
                  }}
                  style={{ marginTop: "5px" }}
                >
                  Volver a mis ordenes
                </Button>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default MisOrdenes;
