import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import Button from "react-bootstrap/Button";
import style from "./contacto.module.scss";
import contactImg from "../../assets/img/contacto.svg";

const auth = firebase.auth();
const db = firebase.firestore();

const Contacto = props => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");
  const [done, setDone] = useState(false);

  const handleSubmit = evt => {
    evt.preventDefault();
    let msj = {
      name,
      lastName,
      email,
      telephone,
      message
    }
    try{
      fetch('https://us-central1-rapipartesok.cloudfunctions.net/contacto', {
      method: 'POST',
      body: JSON.stringify({msj}),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if(response.ok) {
        //console.log(response)
        setName(false)
        setLastName(false)
        setEmail(false)
        setTelephone(false)
        setMessage(false)
        setDone(true);
      } else {
        console.error("Error consultando los datos. \n", response);
        props.history.push("/404");
      }
    })
    }catch(error){
      console.log("Contacto Form",error)
      props.history.push("/404");
    }
  };

  return (
    <>
      {!done ? (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // style={{width:'fit-content', margin:'auto'}}
          className={style.logincont}
          closeButton
        >
          <div className={style.form}>
            <Modal.Header closeButton>
              <Modal.Title>
                <div className={style.formtitulo}>CONTACTO</div>
              </Modal.Title>
            </Modal.Header>
            <div className={style.imgcont}>
              <img src={contactImg} alt="Contacto" />
            </div>

            <form onSubmit={handleSubmit}>
              <div className={style.forminput}>
                <TextField
                  required
                  size="small"
                  id="outlined-basic"
                  label="Nombre"
                  variant="outlined"
                  onChange={e => setName(e.target.value)}
                />
              </div>
              <div className={style.forminput}>
                <TextField
                  required
                  size="small"
                  id="outlined-basic"
                  label="Apellido"
                  variant="outlined"
                  onChange={e => setLastName(e.target.value)}
                />
              </div>
              <div className={style.forminput}>
                <TextField
                  required
                  size="small"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <div className={style.forminput}>
                <TextField
                  required
                  size="small"
                  id="outlined-basic"
                  label="Telefono"
                  variant="outlined"
                  onChange={e => setTelephone(e.target.value)}
                />
              </div>
              <div className={style.forminput}>
                <TextField
                  required
                  size="small"
                  id="outlined-basic"
                  multiline
                  rows={4}
                  label="Mensaje"
                  variant="outlined"
                  onChange={e => setMessage(e.target.value)}
                />
              </div>
              <div className={style.forminput}>
                <Button type="submit">Enviar consulta</Button>
              </div>
            </form>
          </div>
        </Modal>
      ) : (
        <Modal
          {...props}
          size="sm"
          centered
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <div
            style={{
              background: "white",
              borderRadius: "10px",
              textAlign: "center"
            }}
          >
            <div className={style.imgcont}>
              <img src={contactImg} alt="Contacto" />
            </div>
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-sm">
                ¡Gracias por contactarte!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Responderemos tu consulta a la brevedad.
              <Button
                block
                onClick={() => {
                  setDone(false)
                  props.onHide();
                }}
              >
                ¡Ok!
              </Button>
            </Modal.Body>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Contacto;
