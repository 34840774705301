import React, { useState, useEffect } from "react";
import style from "./singleproduct.module.scss";

import { addNewItem } from "../Cart/cartActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";

import dummyImg from "../../assets/img/dummyimg.jpg";
import firebase from "../../firebase";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faCartPlus, faPlus } from "@fortawesome/free-solid-svg-icons";

import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
function Alert(props) {
  return <MuiAlert elevation={0} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));
const SingleProduct = props => {
  const db = firebase.firestore();
  const [product, setProduct] = useState(props.productSolo);
  const [user, setUser] = useState(false);

  const classes = useStyles();
  const [added, setAdded] = useState(false);
  const [repited, setRepited] = useState(false);

  const handleClick = () => {
    setAdded(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setRepited(false)
    setAdded(false);
  };

  let info = props.json[product.id - 1];
  console.log(props.order.user.comision)
  let comision = Number(props.order.user.comision);
  if(!comision)comision = 1
  info.PF = info.PL - ((info.PL * comision) / 100).toFixed(2);

  useEffect(() => {
    console.log(props.pdv)
    if (!user) {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          db.collection("users")
            .doc(user.uid)
            .get()
            .then(async data => {
              let usuario = data.data();
              //console.log("User", usuario);
              setUser(usuario);
            });
        } else {
          console.log("No user!");
        }
      });
    }
  });

  const addItem =()=>{
    let flag = true
    // console.log("PROPS",props)
    // console.log("INFO",info)
    if (props.user) {
      if(props.order.itemsList.length){
        props.order.itemsList.map((art)=>{
          if(art.product.ID == info.ID){
            setRepited(true)
            flag = false
          }
        })
      }
      if(flag){
          let newOrder;
          newOrder = props.order;
          newOrder.user = user;
          props.addNewItem(info, newOrder).then(() => {
            setAdded(true);
          });
      }
    } else {
      props.setModalShow(true);
    }
  }
  return (
    <div>
      {!product.codigo ? (
        <Icon className={style.loading} icon={faCircleNotch} size="3x" spin />
      ) : (
        <div className={style.singleprodcont}>
          <div className={style.product}>
            <div className={style.productinfo}>
              <img
                className={style.imgcont}
                src={product.img ? product.img : dummyImg}
                alt="Imagen de producto"
              />

            <div>
              <div className={style.datalg}>
              <p className={style.texto}>
                <strong>Código:</strong>
                {product.codigo}{" "}
              </p>
      

              <p className={style.texto}>
                <strong>Fábrica:</strong>
                {product.fabrica}{" "}
              </p>
                <p className={style.texto}>
                  <strong> Descripción:</strong> 
                  {product.descripcion}{" "}
                </p>
              </div>
              <div className={style.datalg}>
                <p className={style.texto}>
                  <strong> Aplicación:</strong> 
                  {product.aplicacion}{" "}
                </p>
              </div>
            </div>
            </div>

          <div className={style.dataprice}>
              <p className={style.texto}>
                <strong> Precio de Venta:</strong>
              </p>
              <p className={style.precio}>{props.pdv} </p>
            </div>
            <Button
            style={{marginTop:'10px'}}
            onClick={addItem}
            block
          >
            Agregar al carrito 
          </Button>
          </div>
       
        </div>
      )}
      <Snackbar
        anchorOrigin={{ marginTop:"65px", vertical: "top", horizontal: "center" }}
        open={added}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          ¡El producto fue agregado!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ marginTop:"65px",vertical: "top", horizontal: "center" }}
        open={repited}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="warning">
          ¡El producto ya se encuentra en tu carrito!
          Podes modificar la cantidad dentro del mismo.
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapDispatchToProps = function(dispatch, ownprops) {
  return {
    addNewItem: (item, order) => dispatch(addNewItem(item, order))
  };
};

const mapStateToProps = function(state) {
  return {
    state,
    productos: state.jsonData.data,
    order: state.cart,
    json: state.storageJson.data
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleProduct)
);
