import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Products from "../Products/Products";
const UserDash = props => {
  return (
    <div className="dashboard animated fadeIn fast">
      <Sidebar section={"catalogo"} />
      <div className="dashcontent animated fadeIn fast">
        {props.match.params ? (
          <Products marca={props.match.params.marca} />
        ) : (
          <Products />
        )}
      </div>
    </div>
  );
};

export default UserDash;
