import React from "react";
import style from "./cart.module.scss";

let fontSize = "0.8rem";
let padding = "0.3rem 0.3rem";

const subtotal = (cell,row)=>{
  return row.subtotal.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const precio = (cell,row)=>{
  return row.precio.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const tableColumns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    hidden: true
  },
  {
    dataField: "codigo",
    text: "Código",
    sort: true,
    style: {
      padding,
      fontSize,
      width: "7%"
    }
  },
  {
    dataField: "fabrica",
    text: "Fábrica",
    sort: true,
    style: {
      padding,
      fontSize,
      width: "7%"
    }
  },
  {
    dataField: "descripcion",
    text: "Descripción",
    sort: true,
    style: {
      padding,
      fontSize,
      width: "25%"
    }
  },
  {
    dataField: "aplicacion",
    text: "Aplicación",
    sort: true,
    style: {
      padding,
      fontSize,
      width: "20%"
    }
  },
  {
    dataField: "precio",
    text: "Precio",
    sort: true,
    formatter: precio,
    style: {
      fontSize,
      width: "10%"
    }
  },
  {
    dataField: "minus",
    text: "",
    sort: false,
    cursor: "pointer",
    style: {
      fontSize: "0.8rem",
      color: "grey",
      width: "fit-content",
      verticalAlign: "middle",
      textAlign: "center"
    }
  },
  {
    dataField: "cantidad",
    text: "Cant.",
    sort: true,
    border:'none',
    style: {
      verticalAlign: "middle",
      fontSize,
      width: "5%",
      textAlign: "center"
    }
  },
  {
    dataField: "add",
    text: "",
    sort: false,
    cursor: "pointer",
    style: {
      fontSize: "0.8rem",
      color: "grey",
      width: "2%",
      verticalAlign: "middle",
      textAlign: "center"
    }
  },
  {

    dataField: "subtotal",
    text: "Subtotal",
    formatter: subtotal,
    style: {
      fontSize,
      width: "3%",
      textAlign: "center"
    }
  },
  {
    dataField: "delete",
    text: "",
    sort: false,
    cursor: "pointer",
    style: {
      fontSize: "0.8rem",
      color: "grey",
      width: "2%",
      verticalAlign: "middle",
      textAlign: "center"
    }
  }
];
export const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange
}) => (
  <div className="btn-group" role="group">
    {options.map(option => {
      const isSelect = currSizePerPage === `${option.page}`;
      return (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${isSelect ? "btn-primary" : "btn-outline-primary"}`}
        >
          {option.text}
        </button>
      );
    })}
  </div>
);

export const tableOptions = {
  sizePerPageRenderer
};

export const CheckoutSizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange
}) => (
  <div className="btn-group" role="group">
    {options.map(option => {
      const isSelect = currSizePerPage === `${option.page}`;
      return (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${isSelect ? "btn-primary" : "btn-outline-primary"}`}
        >
          {option.text}
        </button>
      );
    })}
  </div>
);

export const checkoutOptions = {
  CheckoutSizePerPageRenderer
};


export const checkoutColumns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    hidden: true
  },
  {
    dataField: "codigo",
    text: "Código",
    sort: true,
    style: {
      padding,
      fontSize,
      width: "7%"
    }
  },
  {
    dataField: "fabrica",
    text: "Fábrica",
    sort: true,
    style: {
      padding,
      fontSize,
      width: "7%"
    }
  },
  {
    dataField: "descripcion",
    text: "Descripción",
    sort: true,
    style: {
      padding,
      fontSize,
      width: "25%"
    }
  },
  {
    dataField: "aplicacion",
    text: "Aplicación",
    sort: true,
    style: {
      padding,
      fontSize,
      width: "20%"
    }
  },
  {
    dataField: "precio",
    text: "Precio",
    sort: true,
    formatter: precio,
    style: {
      fontSize,
      width: "10%"
    }
  },
  {
    dataField: "cantidad",
    text: "Cant.",
    sort: true,
    border:'none',
    style: {
      verticalAlign: "middle",
      fontSize,
      width: "5%",
      textAlign: "center"
    }
  },
  {

    dataField: "subtotal",
    text: "Subtotal",
    formatter: subtotal,
    style: {
      fontSize,
      width: "3%",
      textAlign: "center"
    }
  },
  {
    dataField: "delete",
    text: "",
    sort: false,
    cursor: "pointer",
    style: {
      fontSize: "0.8rem",
      color: "grey",
      width: "2%",
      verticalAlign: "middle",
      textAlign: "center"
    }
  }
];



export const orderColumns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    hidden: true
  },
  {
    dataField: "codigo",
    text: "Código",
    sort: true,
    style: {
      padding,
      fontSize,
      width: "7%"
    }
  },
  {
    dataField: "fabrica",
    text: "Fábrica",
    sort: true,
    style: {
      padding,
      fontSize,
      width: "7%"
    }
  },
  {
    dataField: "descripcion",
    text: "Descripción",
    sort: true,
    style: {
      padding,
      fontSize,
      width: "25%"
    }
  },
  {
    dataField: "aplicacion",
    text: "Aplicación",
    sort: true,
    style: {
      padding,
      fontSize,
      width: "20%"
    }
  },
  {
    dataField: "precio",
    text: "Precio",
    sort: true,
    formatter: precio,
    style: {
      fontSize,
      width: "10%"
    }
  },
  {
    dataField: "cantidad",
    text: "Cant.",
    sort: true,
    border:'none',
    style: {
      verticalAlign: "middle",
      fontSize,
      width: "5%",
      textAlign: "center"
    }
  },
  {

    dataField: "subtotal",
    text: "Subtotal",
    formatter: subtotal,
    style: {
      fontSize,
      width: "3%",
      textAlign: "center"
    }
  }
];