import firebase from "../../firebase";
const db = firebase.firestore();
const orderCount = db.collection("orders").doc("--stats--");
const increment = firebase.firestore.FieldValue.increment(1);

export const cartDB = {};

//busca el valor contador del server
//setea el id de la order
//crea la orden con el id
// retorna la orden creada
const createOrder = async order => {
  if (order.user.id) {
    let numOrder;
    let newOrder = order;
    await orderCount.get({ source: "server" }).then(async res => {

      numOrder = res.data().numOrder;
      
      //console.log('creando la orden ',numOrder);
      
      newOrder.id = numOrder;
      newOrder.date = new Date();
      await orderCount.update({ numOrder: increment }).then(() => {});
      await db
        .collection("orders")
        .doc(numOrder.toString())
        .set(newOrder)
        .then(() => {});
    });
    return newOrder;
  } else if (!order.user.id) {
    return console.log("no habia usuario y no se creo orden");
  }
};
cartDB.createOrder = createOrder;
// el viernes a las 4:16 esta funcion andaba bien!

//busca el valor contador del server
//setea el id de la order
//crea la orden con el id
const getUserOrder = async userid => {
  let result = [];
  //console.log("antes de empezar la busqueda", result);
  await db
    .collection("orders")
    .where("user.id", "==", userid)
    .where("status", "==", "on_cart")
    .where("id", "!=", 0)
    .orderBy("id", "desc")
    .limit(1)
    .get()
    .then(querySnapshot => {
      querySnapshot.docs.map(res => {
        result.push(res.data());
        //console.log("Res.Data!!!", res.data());
      });
    });
  //console.log("despues de la busqueda", result[0]);
  if (!result[0]) {
    return false;
  } else return result[0];
};
cartDB.getUserOrder = getUserOrder;

//update order utliza .add() y no elimina data anterior
const orderUpdate = async incomingOrder => {
  let currentOrder;
  let oldOrder;
  if (incomingOrder.user.id) {
    oldOrder = await getUserOrder(incomingOrder.user.id);
  }

  if (!oldOrder) {
    currentOrder = await createOrder(incomingOrder).then(() => {
      //console.log("no habia orden y fue creada");
    });
    return currentOrder;
  }
  if (oldOrder) {
    await db
      .collection("orders")
      .doc(oldOrder.id.toString())
      .update(incomingOrder) //si usas set borra todo chinchulin
      .then(() => {
        //console.log("su carrito fue actualizado");
      });
  }
};

cartDB.orderUpdate = orderUpdate;

//deleteItem utiliza .set() y sobreescribe la orden
const deleteItem = async incomingOrder => {

  await db
    .collection("orders")
    .doc(incomingOrder.id.toString())
    .set(incomingOrder) //si usas set borra todo chinchulin, es la idea pa'
    .then(() => {
      //console.log("El item fue eliminado primopeeeeeeeee");
    });
};

cartDB.deleteItem = deleteItem;

const getTotal = newOrder => {
  if (newOrder.discount) {
    //console.log("hola", newOrder);
    return Math.floor(newOrder.orderSubtotal * (1 - newOrder.discount / 100))
      .toFixed(2)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return newOrder.orderSubtotal
      .toFixed(2)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
};

cartDB.getTotal = getTotal;

const getSubtotal = newOrder => {
  let orderSubtotal = 0;
  if (newOrder.itemsList.length === 0) {
    return 0;
  }
  newOrder.itemsList.map(item => {
    //console.log("aqui suma al subtotal", item.subtotal);

    orderSubtotal += item.subtotal;
  });
  return orderSubtotal;
};

cartDB.getSubtotal = getSubtotal;

const getItemSubtotal = item => {
  return item.product.PF * item.quantity;
};
cartDB.getItemSubtotal = getItemSubtotal;
