import React from "react";
import style from './footer.module.scss'
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";


const Footer = () => {


  return (
    <div className={style.container} >
      <div className={style.footertext} >
        2020 RAPIPARTES | Desarrollado con ⚡  por   

      <a className={style.navbarlink} target='_blank' href="https://www.powerit.ar">

      PowerIT 
      </a>
      </div>


     
    </div>
  )
};

export default Footer;
