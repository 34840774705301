import React, { useState, useEffect } from "react";
import firebase from '../../firebase';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import style from './products.module.scss'
import {
    orderColumns,
    checkoutOptions,
    tableOptions
  } from "../Cart/tableModule";
  import logoText from "../../assets/img/logotexto.svg";

  import Button from "react-bootstrap/Button";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faMinus } from "@fortawesome/free-solid-svg-icons";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";

const db = firebase.firestore();

const SingleOrder = (props) => {

  const [order, setOrder] = useState(false);
  const [showOrder, setShowOrder] = useState(false);

    useEffect(()=>{
       setOrder(props.order)
    },[order])

    let setItems = arr => {
        let itemsDisplay = [];
        arr.map(item => {
          return itemsDisplay.push({
            id: item.product.ID,
            codigo: item.product.CODIGO,
            fabrica: item.product.FABRICA,
            descripcion: item.product.DESCRIPCION,
            aplicacion: item.product.APLICACION,
            precio: item.product.PF,
            cantidad: item.quantity,
            subtotal: item.subtotal
          });
        });
    
        return itemsDisplay;
      };
    
    return (
        <div>
           {order ? (
               <div className={style.completedordercont}>
                 <div className={style.checkoutcont}>
                   <div className={style.section}>
                     <div>
                       <p className={style.text}>
                         {" "}
                         Orden: <div className={style.value}>{order.id}</div>
                       </p>
                       <p className={style.textgreen}>
                         {" "}
                         <Icon size="sm" icon={faDotCircle} /> {order.status}
                       </p>
                     </div>
   
                     <img
                       style={{ height: "25px" }}
                       src={logoText}
                       alt="rapipartes"
                     />
                   </div>
                   <br />
                   <BootstrapTable
                     keyField="id"
                     hover
                     striped
                     bordered={false}
                     data={setItems(order.itemsList)}
                     columns={orderColumns}
                     //pagination={paginationFactory(tableOptions)}
                   />
                   <hr />
                   <div className={style.section}>
                     <div className={style.contactinfo}>
                     <div className={style.formtitulo}>
                         Negocio: <strong> {order.user.negocio}</strong>
                       </div>
                       <div className={style.formtitulo}>
                         CUIT: <strong> {order.user.cuit}</strong>
                       </div>
                       <div className={style.formtitulo}>
                         Telefono registrado: <strong> {order.user.telephone}</strong>
                       </div>
                       <div className={style.formtitulo}>
                         Email: <strong> {order.user.email}</strong>
                       </div>
                       <div className={style.formtitulo}>
                         Usuario: <strong> {order.user.firstName +" "+ order.user.lastName}</strong>
                       </div>
                       <div className={style.formtitulo}>
                         Comisión aplicada: <strong> {order.user.comision}</strong>
                       </div>
                       <div className={style.formtitulo}>
                         Numero de contacto: <strong> {order.contactPhone}</strong>
                       </div>
                       {order.deliveryAddres.street ? (
                         <>
                           <div className={style.formtitulo}>
                             Dirección de entrega:
                           </div>
                           <div>
                             {order.deliveryAddres.street},{" "}
                             {order.deliveryAddres.number},{" "}
                             {order.deliveryAddres.floorUnit}
                           </div>
   
                           <div>
                             {order.deliveryAddres.city},{" "}
                             {order.deliveryAddres.province},{" "}
                             {order.deliveryAddres.postCode}
                           </div>
   
                           <div>Comentarios: {order.deliveryAddres.comments}</div>
                         </>
                       ) : null}
                     </div>
                     <div className={style.totalcont}>
                       <p className={style.text}>
                         Subtotal:
                         <p className={style.value}>
                           {order.orderSubtotal.toFixed(2)}
                         </p>
                       </p>
                       <hr />
                       {order.discount > 0 ? (
                         <p>Descuento:{order.discount}%</p>
                       ) : null}
   
                       <p className={style.text}>
                         Total:
                         <p className={style.total}>{order.total}</p>
                       </p>
                     </div>
                   </div>
                   
                 </div>
          
             </div>


            //    <div>
            //         <p>-------------------------------------------------------------------------------------------------------------</p>
            //         <p>{`Negocio:${order.user.negocio+" "+" "+" "+" "}Usuario:${order.user.firstName +" "+ order.user.lastName+" "+" "+" "}Comisión aplicada:${order.user.comision}`}</p>
            //         <p>CUIT:{order.user.cuit + "      "+ "Telefono:"+order.user.telephone + "Email: " + order.user.email }</p>
            //         <p>-------------------------------------------------------------------------------------------------------------</p>
            //         <p>Orden ID:{order.id}</p>
            //         {order.itemsList.map(item=>{
            //             return (
            //                 <div>
            //                     <p>-{item.product.CODIGO} // {item.product.FABRICA}</p>
            //                     <p>---{item.product.APLICACION}  // {item.product.DESCRIPCION}</p>
            //                     <p>------ Cantidad x{item.quantity}  // Precio Unitario ${item.product.PF} // Precio Total ${item.subtotal}</p>
            //                 </div>
            //             )
            //         })}
            //         <p>Orden Total:{order.total}</p>
            //         <p>-------------------------------------------------------------------------------------------------------------</p>
            //    </div>
           ):(<p>Loading...</p>)}
        </div>
      
    )
};

export default SingleOrder;