const initialState = {
    data: false
}

export default (state = initialState,action)=>{
    switch (action.type) {
        case "STORAGE_JSON":
            return {...state, data: action.data}
        default:
            return state    
    }
}