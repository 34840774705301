import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import style from "./user.module.scss";
import registerFondo from "../../assets/img/registerfondo.jpg";
import { withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import contactImg from "../../assets/img/contacto.svg";

import firebase from "../../firebase";
const auth = firebase.auth();
const db = firebase.firestore();
const Register = props => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [negocio, setNegocio] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");
  const [cuit, setCuit] = useState(false);
  const [telephone, setTelephone] = useState("");
  const [done, setDone] = useState(false);
  const [creating, setCreating] = useState(false);

  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  const handleSubmit = async evt => {
    evt.preventDefault();
    try {
      //console.log("hola");
      await auth
        .createUserWithEmailAndPassword(email, password)
        .then( user => {
          setCreating(true);
           auth.onAuthStateChanged(async function(user) {
            //console.log('EMPEZAMOS A CREAR EL USER');
            
            await db
              .collection("users")
              .doc(user.uid)
              .set({
                id: user.uid,
                date: new Date(),
                firstName,
                lastName,
                email,
                negocio,
                cuit,
                telephone,
                comision: 0,
                extra: 0,
                utilidad: 30,
              })
              .then(res => {
                //console.log('SE CREO EL USER');
                
                setTimeout(function() {
                  setCreating(false);
                  setDone(true);
                }, 3000);
              });
          });
        });
    } catch (error) {
      //console.log(error);
      console.log(error);
      let result = {};
      switch (error.code) {
        case "auth/invalid-email":
          result = {
            target: "email",
            msg: "El email ingresado no es válido."
          };
          break;
        case "auth/weak-password":
          result = {
            target: "pass",
            msg: "Tu contraseña debe ser al menos 6 carácteres."
          };
          break;
        case "auth/too-many-requests":
          result = { target: "all", msg: "Intente de nuevo mas tarde." };
          break;
        case "auth/email-already-in-use":
          result = {
            target: "email",
            msg: "El email ingresado ya se encuentra registrado."
          };
          break;
          default:
         result = { target: "all", msg: "Intente de nuevo mas tarde" };
    }
    setErrorMsg(result.msg)
    setError(true)

  }}

  return (
    <div className={style.register+' animated fadeIn fast'}>
      <div className={style.formcontainer}>
        <form className={style.form} onSubmit={handleSubmit}>
          <p className={style.formtitulo}>
            NUEVO <br /> USUARIO
          </p>
          <div className={style.forminput}>
            <TextField
              required
              className={style.input}
              size="small"
              onChange={e => {
                setNegocio(e.target.value);
              }}
              type="negocio"
              name="negocio"
              label="Empresa"
              variant="outlined"
            />
          </div>

          <div className={style.forminput}>
            <TextField
              required
              className={style.input}
              size="small"
              onChange={e => {
                setCuit(e.target.value);
              }}
              type="text"
              placeholder="30-00000000-0"
              name="cuit"
              label="CUIT"
              variant="outlined"
            />
          </div>
          <div className={style.forminput}>
            <TextField
              required
              className={style.input}
              size="small"
              onChange={e => {
                setTelephone(e.target.value);
              }}
              type="telephone"
              name="telephone"
              label="Teléfono"
              variant="outlined"
            />
          </div>

          <div className={style.forminput}>
            <TextField
              required
              className={style.input}
              size="small"
              onChange={e => {
                setFirstName(e.target.value);
              }}
              type="text"
              name="firstName"
              label="Nombre"
              variant="outlined"
            />
          </div>

          <div className={style.forminput}>
            <TextField
              required
              className={style.input}
              size="small"
              onChange={e => {
                setLastName(e.target.value);
              }}
              type="text"
              name="apellido"
              label="Apellido"
              variant="outlined"
            />
          </div>

          {/* <input
          onChange={e => {
            setLastName(e.target.value);
          }}
          type="text"
          placeholder="Apellido"
        /> */}

          <div className={style.forminput}>
            <TextField
              required
              className={style.input}
              size="small"
              onChange={e => {
                setEmail(e.target.value);
              }}
              type="text"
              placeholder="email"
              name="email"
              label="email"
              variant="outlined"
            />
          </div>
          <div className={style.forminput}>
            <TextField
              required
              className={style.input}
              size="small"
              onChange={e => {
                setPassword(e.target.value);
              }}
              type="password"
              name="password"
              error={password != passwordConf}
              label="Contraseña"
              variant="outlined"
              helperText={
                password != passwordConf ? "Las contraseñas no coinciden." : ""
              }
            />
          </div>

          <div className={style.forminput}>
            <TextField
              required
              className={style.input}
              size="small"
              onChange={e => {
                setPasswordConf(e.target.value);
              }}
              type="password"
              name="passwordConf"
              label="Confirma tu contraseña"
              variant="outlined"
            />
          </div>
          <div className={style.btn}>
            <Button 
            disabled={password != passwordConf}
            className="btn btn-block btn-primary " type="submit">
              Crear cuenta
            </Button>
          </div>
        </form>
      </div>

      <div className={style.imgcont}>
        {/* <img src={registerFondo} alt=""/>  */}
      </div>
      <Modal
        size="sm"
        show={done}
        centered
        backdrop="static"
        onHide={() => 
          console.log('INTENTE CERRAR')
      }
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <div
          style={{
            background: "white",
            borderRadius: "10px",
            textAlign: "center"
          }}
        >
          <div className={style.imgcont}>
            <img src={contactImg} alt="Contacto" />
          </div>
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-sm">
                   Registro exitoso!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            En breve podrás agregar productos al carrito.
            <Button
              block
              onClick={() => {
                props.history.push("/micuenta");
                setDone(false);
              }}
            >
              ¡Ok!
            </Button>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        size="sm"
        show={creating}
        onHide={()=>{console.log('INTENTE CERRAR');
        }}
        backdrop="static"
        centered
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <div
          style={{
            background: "white",
            borderRadius: "10px",
            textAlign: "center"
          }}
        >
          <div className={style.imgcont}>
            <img src={contactImg} alt="Contacto" />
          </div>
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-sm">
              ¡Estamos creando tu usuario!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Pronto vas a poder agregar productos al carrito y hacer pedidos
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        size="sm"
        show={error}
        onHide={()=>{console.log('INTENTE CERRAR')}}
        backdrop="static"
        centered
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <div
          style={{
            background: "white",
            borderRadius: "10px",
            textAlign: "center"
          }}
        >
          <div className={style.imgcont}>
            <img src={contactImg} alt="Contacto" />
          </div>
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-sm">
              ¡Ocurrio un error!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorMsg}
            {errorMsg  === "El email ingresado ya se encuentra registrado." 
          ?(<p className='text-muted' onClick={()=>{
            auth.sendPasswordResetEmail(email)
            .then(function (user) {
              alert('¡Email enviado, revisa tu bandeja de entrada!')
            }).catch(function (e) {
              console.log(e)
            })
          }} >Recuperar contraseña</p>):null}
            <Button
              block
              onClick={() => {
                setError(false);
              }}
            >
              ¡Entendido!
            </Button>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default Register;
