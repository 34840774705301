import React, { useState } from "react";
import style from "./home.module.scss";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import Button from "react-bootstrap/Button";
import contactImg from "../../assets/img/contacto.svg";
import Modal from "react-bootstrap/Modal";
// import Logos
import logoDze from '../../assets/img/dze.png'
import logoDivaio from '../../assets/img/divaio.png'
import logoKobla from '../../assets/img/kobla.png'
import logoGenoud from '../../assets/img/genoud.png'
import logoKessel from '../../assets/img/kessel.png'
import logoOrlanRober from '../../assets/img/orlanRober.png'
import logoKobo from '../../assets/img/kobo.png'
import logoPhilips from '../../assets/img/philips.png'
import logoRgupro from '../../assets/img/rgupro.png'
import logoVuaram from '../../assets/img/vuaram.png'

//import images
import banner1 from "../../assets/img/banner1.png";
import banner2 from "../../assets/img/banner2.png";
import banner3 from "../../assets/img/banner3.png";
import sobrenosotros from "../../assets/img/sobrenosotros.jpg";
// Import icons
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faMapMarker,
  faHandsHelping,
  faClock,
  faAward,
  faShippingFast,
  faDollarSign,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

// Import Swiper React components
import SwiperCore, {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { useEffect } from "react";
import firebase from "../../firebase";  

const Home = props => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");
  const [modalContact, setModalContact] = useState(false);
  const [promos, setPromos] = useState(false)

  const db = firebase.firestore();

  useEffect(()=>{
    if(!promos){
      db.collection("promos").onSnapshot(function(docs) {
        let array = [];
        docs.forEach(doc => {
          const element = doc.data();
          element.key = doc.id;
          array.push(element);
        });
        // console.log(array)
        setPromos(array);
      });
    }
  },[])

  const handleSubmit = evt => {
    evt.preventDefault();
    let msj = {
      name,
      lastName,
      email,
      telephone,
      message
    }
    try{
      fetch('https://us-central1-rapipartesok.cloudfunctions.net/contacto', {
      method: 'POST',
      body: JSON.stringify({msj}),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if(response.ok) {
        //console.log(response)
        setName(false)
        setLastName(false)
        setEmail(false)
        setTelephone(false)
        setMessage(false)
        setModalContact(true);
      } else {
        console.error("Error consultando los datos. \n", response);
        props.history.push("/404");
      }
    })
    }catch(error){
      console.log("Contacto Form",error)
      props.history.push("/404");
    }
    
  };

  SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

  return (
    <div className={style.homecontainer}>
      {/* //////////////////////
      //////////CAROUSEL////////
      ////////////////////// */}
      <div className={style.carouselcontainer}>
        <div className={style.carousel}>
          <Carousel
            fade
            controls={false}
            indicators={false}
            className={style.carousel}
          >
            {promos.length ? (
              promos.map(pro =>{
                return <Carousel.Item>
                  <img className="d-block w-100" src={pro.url}  />
              </Carousel.Item>
              })
            ):(
            <Carousel.Item>
              <img className="d-block w-100" src={banner1} alt="First slide" />
            </Carousel.Item>
            )}
           
          </Carousel>
        </div>
      </div>

      {/* //////////////////////
      //////////BOTONES////////
      ////////////////////// */}
      <div style={{ marginBlockStart: "20px" }} className={style.buttongroup}>
        <Link
          to={{
            pathname: "/catalogo",
            state: { fromDashboard: true }
          }}
        >
          <div className={style.buttonsimple}>CATÁLOGO</div>
        </Link>
        <Link to="/ofertas">
          <div className={style.buttonsimple}>OFERTAS</div>
        </Link>
        <Link to="/catalogo">
          <div className={style.buttonsimple}>NOVEDADES</div>
        </Link>

        <Link to="/micuenta">
          <div className={style.buttonsimple}>MI CUENTA</div>
        </Link>
      </div>
      <div className={style.buttongroup}>
        <a href="https://wa.link/xot8p3" target="_blank">
          <div className={style.buttonicono}>
            <Icon icon={faWhatsapp} color="#3b70b3" size="3x" />
            <p className={style.buttoniconotext}>
              Atención mayorista via whatsapp
            </p>
          </div>
        </a>
        <a href="tel:+5401135337788">
          <div className={style.buttonicono}>
            <Icon icon={faPhoneAlt} color="#3b70b3" size="3x" />
            <p className={style.buttoniconotext}>
              Atención telefónica <br /> 011 3533 7788
            </p>
          </div>
        </a>
        <a href="tel:+5401135337788">
          <div className={style.buttonicono}>
            <Icon icon={faHandsHelping} color="#3b70b3" size="3x" />
            <p className={style.buttoniconotext}>
              Atención personalizada para cuentas mayoristas
            </p>
          </div>
        </a>
        <Link to="/register">
          <div className={style.buttonicono}>
            {/* <Icon icon={faMapMarker} color="#3b70b3" size="3x" /> */}
            <p className={style.buttoniconotext}>
              <strong>
                ¿No tienes cuenta? <br /> Registrate!{" "}
              </strong>
              <br />
              Es muy simple!
            </p>
          </div>
        </Link>
      </div>

      {/* //////////////////////
      //////////MARCAS////////
      ////////////////////// */}
      <div className={style.marcas}>
        <div className={style.titulomarcas}>
          <div className={style.linea}> </div>

          <div className={style.texto}>MARCAS</div>
        </div>
        {/* //////////////////////
      //////////SWIPER////////
      ////////////////////// */}
        <Swiper
          style={{ width: "90vw" }}
          effect="fade"
          navigation
          pagination={{ clickable: true }}
          slidesPerView={1}
          autoplay
          loop
        >
          {/* //////////////////////
      //////////1ER SLIDE////////
      ////////////////////// */}
          <SwiperSlide>
            <div className={style.contenedormarcas}>
              <Link to="/catalogo/dze">
                <div className={style.contenedorlogo}>
                  <img className={style.logo} src={logoDze} alt="Logo Dze"/>
                </div>
              </Link>
              <Link to="/catalogo/divaio">
                <div className={style.contenedorlogo}>
                  <img className={style.logo} src={logoDivaio} alt="Logo divaio"/>
                </div>
              </Link>
              <Link to="/catalogo/kobla">
                <div className={style.contenedorlogo}>
                  <img className={style.logo} src={logoKobla} alt="Logo kobla"/>
                </div>
              </Link>
              <Link to="/catalogo/philips">
                <div className={style.contenedorlogo}>
                  <img className={style.logo} src={logoPhilips} alt="Logo phillips"/>
                </div>
              </Link>
              <Link to="/catalogo/rgu">
                <div className={style.contenedorlogo}>
                  <img className={style.logo} src={logoRgupro} alt="Logo Rgu pro"/>
                </div>
              </Link>
              
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={style.contenedormarcas}>
              <Link to="/catalogo/genoud">
                <div className={style.contenedorlogo}>
                  <img className={style.logo} src={logoGenoud} alt="Logo Genoud"/>
                </div>
              </Link>
              <Link to="/catalogo/kessel">
                <div className={style.contenedorlogo}>
                  <img className={style.logo} src={logoKessel} alt="Logo kessel"/>
                </div>
              </Link>
              <Link to="/catalogo/orlan">
                <div className={style.contenedorlogo}>
                  <img className={style.logo} src={logoOrlanRober} alt="Logo Orlan Rober"/>
                </div>
              </Link>
              <Link to="/catalogo/kobo">
                <div className={style.contenedorlogo}>
                  <img className={style.logo} src={logoKobo} alt="Logo kobo"/>
                </div>
              </Link>
              <Link to="/catalogo/vuaram">
                <div className={style.contenedorlogo}>
                  <img className={style.logo} src={logoVuaram} alt="Logo vuaram"/>
                </div>
              </Link>

              
            </div>
          </SwiperSlide>
        </Swiper>
      </div>



      {/* //////////////////////
      //////////SOBRE NOSOTROS////////
      ////////////////////// */}

      <div className={style.sobrenosotros}>
        <div>
          <img
            className={style.sobreimagen}
            src={sobrenosotros}
            alt="Sobre nosotros, camion con muchas partes"
          />
        </div>
        <div className={style.sobretexto}>
          <div className={style.sobretitulo}>
            SOBRE <br /> NOSOTROS
          </div>
          <p className={style.descripcion}>
            Con mas de diez años de experiencia en el mercado de reposición
            automotor, brindamos un servicio de excelencia basado en cuatro
            pilares: Atención personalizada, Precios líderes, Rapida entrega y
            Amplio stock. Nuestro compromiso es brindar a nuestros clientes
            todas las herramientas para potenciar sus negocios y al mismo tiempo
            crear relaciones de confianza que perduren en el tiempo.{" "}
          </p>
        </div>
      </div>
      {/* //////////////////////
      //////////BENEFICIOS////////
      ////////////////////// */}

<div className={style.beneficios}>
        <div className={style.card}>
          <Icon
            className={style.icono}
            icon={faClock}
            color="#3b70b3"
            size="4x"
          />
          <div className={style.beneficiotitulo}>ATENCIÓN</div>
          <p className={style.beneficiotexto}>
            Atención rápida y personalizada. Atendemos todas las necesidades de compra de autopartes.
          </p>
        </div>
        <div className={style.card}>
          <Icon
            className={style.icono}
            icon={faDollarSign}
            color="#3b70b3"
            size="4x"
          />
          <div className={style.beneficiotitulo}>PRECIOS</div>
          <p className={style.beneficiotexto}>
            Precios líderes en el mercado de reposición
            automotor con descuentos personalizados y por cantidad.
          </p>
        </div>
        <div className={style.card}>
          <Icon
            className={style.icono}
            icon={faShippingFast}
            color="#3b70b3"
            size="4x"
          />
          <div className={style.beneficiotitulo}>ENVIOS</div>
          <p className={style.beneficiotexto}>
            Coordinamos el envío para que se te haga lo mas cómodo posible y te concentres en las partes que buscás.
          </p>
        </div>
        
        <div className={style.card}>
          <Icon
            className={style.icono}
            icon={faAward}
            color="#3b70b3"
            size="4x"
          />
          <div className={style.beneficiotitulo}>STOCK</div>
          <p className={style.beneficiotexto}>
          Con mas de diez años en el mercado de reposición
            automotor, contamos con un amplio stock.
          </p>
        </div>
      </div>
      {/* //////////////////////
      //////////CONTACTO////////
      ////////////////////// */}

      <div id="contacto" className={style.contacto}>
        <div className={style.form}>
          <div className={style.formtitulo}>CONTACTO</div>

          <form onSubmit={handleSubmit}>
            <div className={style.forminput}>
              <TextField
                required
                size="small"
                id="outlined-basic"
                label="Nombre"
                variant="outlined"
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className={style.forminput}>
              <TextField
                required
                size="small"
                id="outlined-basic"
                label="Apellido"
                variant="outlined"
                onChange={e => setLastName(e.target.value)}
              />
            </div>
            <div className={style.forminput}>
              <TextField
                required
                size="small"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className={style.forminput}>
              <TextField
                required
                size="small"
                id="outlined-basic"
                label="Telefono"
                variant="outlined"
                onChange={e => setTelephone(e.target.value)}
              />
            </div>
            <div className={style.forminput}>
              <TextField
                required
                size="small"
                id="outlined-basic"
                multiline
                rows={4}
                label="Mensaje"
                variant="outlined"
                onChange={e => setMessage(e.target.value)}
              />
            </div>
            <div className={style.forminput}>
              <Button type="submit">Enviar consulta</Button>
            </div>
          </form>
        </div>
        <div className={style.info}>
          <a href="tel:+5401135337788" className={style.whitelink}>
            <div className={style.iteminfo}>
              <Icon icon={faPhoneAlt} color="#707070" size="3x" />
              <p>
                Teléfono <br />
                011 3533 7788
              </p>
            </div>
          </a>
          <a
            href="https://wa.link/xot8p3"
            target="_blank"
            className={style.whitelink}
          >
            <div className={style.iteminfo}>
              <Icon icon={faWhatsapp} color="#707070" size="3x" />
              <p>
                Teléfono <br />
                +54 911 2161 1827
              </p>
            </div>
          </a>
          <div className={style.iteminfo}>
            <Icon icon={faEnvelope} color="#707070" size="3x" />
            <p>
              Mail <br />
              info.rapipartes@gmail.com
            </p>
          </div>
          <div className={style.iteminfo}>
            <Icon icon={faMapMarker} color="#707070" size="3x" />
            <p>
              Ubicación <br />
              Palermo, CABA
            </p>
          </div>
        </div>
        <div className={style.mapa}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13138.214674279898!2d-58.423998633808786!3d-34.59015848791479!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2sar!4v1597981446136!5m2!1ses-419!2sar"
            width="300"
            height="500"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
      <Modal
        size="sm"
        show={modalContact}
        centered
        onHide={() => setModalContact(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <div
          style={{
            background: "white",
            borderRadius: "10px",
            textAlign: "center"
          }}
        >
          <div className={style.imgcont}>
            <img src={contactImg} alt="Contacto" />
          </div>
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-sm">
              ¡Gracias por contactarte!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Responderemos tu consulta a la brevedad.
            <Button
              block
              onClick={() => {
                setModalContact(false);
              }}
            >
              ¡Ok!
            </Button>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default Home;
