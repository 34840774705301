const initialState = {
  id: 0,
  user: {
    extra:0,
  },
  status: "created",
  deliveryAddres: {
    street: "",
    number: "",
    floorUnit: "",
    city: "",
    province:'',
    postcode: "",
    comments:''
  },
  itemsList: [],
  contactPhone: '',
  paymentInfo: {},
  orderSubtotal: 0,
  // get orderSubtotal() {
  //   let orderSubtotal = 0;
  //   if (this.itemsList.length === 0) {
  //       return 0
  //   }
  //   this.itemsList.map(item => {
  //       console.log('aqui suma al subtotal',(parseFloat(item.subtotal)) );
        
  //     orderSubtotal += (parseFloat(item.subtotal))
  //   });
  //   return orderSubtotal.toFixed(2)
  // },
  discount: 0,
  total:0,
  // get total() {
  //   return Math.floor(this.orderSubtotal * (1 - this.discount / 100));
  // }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_ORDER": //nombre action
      return {
        ...state,
        id: action.data.id,
        user: action.data.user,
        status: action.data.status,
        deliveryAddres: action.data.deliveryAddres,
        itemsList: action.data.itemsList,
        paymentInfo: action.data.paymentInfo,
        discount: action.data.user.extra,
        orderSubtotal: action.data.orderSubtotal,
        total:action.data.total,
      };
    case "UPDATE_ITEMS": //nombre action
      return {
        ...state,
        itemsList: action.data.itemsList,
        orderSubtotal: action.data.orderSubtotal,
        total:action.data.total,
      };
    default:
      return state;
  }
};


// este reducer va al index de los reducers
// plug & play 