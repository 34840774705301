import React, { useState, useEffect } from "react";
import style from "./sidebar.module.scss";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import Cart from "../Cart/Cart";
import Contacto from '../Contacto/Contacto'

import Login from '../Users/Login'
const db = firebase.firestore();

const Sidebar = ({ section }) => {
  const [user, setUser] = useState(false);
  const [loginShow, setLoginShow] = React.useState(false);
  const [cartShow, setCartShow] = React.useState(false);
  const [contactoShow, setContactoShow] = React.useState(false);
  const [modalAct, setModalAct] = React.useState(false)

  useEffect(() => {
    if (!user) {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          db.collection("users")
            .doc(user.uid)
            .get()
            .then(async data => {
              let usuario = data.data();
              //console.log("User", usuario);
              if(usuario){
                setUser(usuario);
              }
            });
        } else {
          console.log("No user!");
        }
      });
    }
  });

  return (
    <div className={style.sidebarcontainer}>
      <div className={style.user}>
        <div className={style.usericoncont}>
          <Icon className={style.usericon} icon={faUser} />
        </div>
        <p className={style.userName+' '+'animated fadeIn fast'}>
          Usuario: <br/>
          {user
            ? user.firstName.charAt(0).toUpperCase() +
              user.firstName.slice(1) +
              " " +
              user.lastName.charAt(0).toUpperCase() +
              user.lastName.slice(1)
            : <Link to='/register'>
            Registrate
            </Link>}
        </p>
      </div>

      <div className={ !modalAct && section === "incio" ? style.active : style.nav}>
        <div className={style.line} />
        <Link to="/home">
          <p className={style.text}>Inicio</p>
        </Link>
      </div>

      <div className={ !modalAct && section === "micuenta" ? style.active : style.nav}>
        <div className={style.line} />
        <Link to="/micuenta">
          <p className={style.text}>Mi Cuenta</p>
        </Link>
      </div>

      <div className={ !modalAct && section === "catalogo" ? style.active : style.nav}>
        <div className={style.line} />
        <Link to="/catalogo">
          <p className={style.text}>Catálogo</p>
        </Link>
      </div>


      <div className={ !modalAct && section === "checkout" ? style.active : style.nav}>
        <div className={style.line} />
        <Link to="/checkout">
          <p className={style.text}>Realizar pedido</p>
        </Link>
      </div>

      <div className={ !modalAct && section === "pedidos" ? style.active : style.nav}>
        <div className={style.line} />
        <Link to="/ordenes">
          <p className={style.text}>Mis pedidos</p>
        </Link>
      </div>

      <div className={ !modalAct && section === "descargas" ? style.active : style.nav}>
        <div className={style.line} />
        <Link to="/descargas">
          <p className={style.text}>Descargas</p>
        </Link>
      </div>

      <div className={ !modalAct && section === "ofertas" ? style.active : style.nav}>
        <div className={style.line} />
        <Link to="/ofertas">
          <p className={style.text}>Ofertas</p>
        </Link>
      </div>

      <div className={contactoShow ? style.active : style.nav}>
        <div className={style.line} />
        <Link onClick={(e)=>{{
          setModalAct(true)
          setContactoShow(true)}}}>
          <p className={style.text}>Contacto</p>
        </Link>
      </div>
      <Login 
      show={loginShow}
      onHide={() => setLoginShow(false)}
      />
                  <Contacto 
      show={contactoShow}
      onHide={() => {
        setModalAct(false)
        setContactoShow(false)}}
      />
      {user.admin ? (
      <div className={ !modalAct && section === "dashboard" ? style.active : style.nav}>
        <div className={style.line} />
        <Link to="/admin">
          <p className={style.text}>Dashboard</p>
        </Link>
      </div>

      ):(null)}
    </div>
  );
};

export default Sidebar;
