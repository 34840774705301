import React, { useState, useEffect } from "react";
import { updateOrder, deleteItem } from "./cartActions";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import style from "./cart.module.scss";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faCartPlus,
  faCircleNotch
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt, faDotCircle } from "@fortawesome/free-regular-svg-icons";
import EmptyCartImg from "../../assets/img/emptycart.svg";
import logoText from "../../assets/img/logotexto.svg";

//tabla productos, columnas estilos y paginación
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { orderColumns, tableColumns, tableOptions } from "./tableModule";
import Axios from "axios";
//base de datos
import { cartDB } from "./firestoreModule";
import firebase from "../../firebase";
import { saveProducts } from "../../store/actions/saveProducts";
import { saveJson } from "../../store/actions/saveJson";
import { addNewItem } from "../Cart/cartActions";


// ☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀
// ☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀
// ☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀
// ☀☀☀☀☀☀☀☀☀☀COMPONENTE CART☀☀☀☀☀☀☀☀☀☀☀
// ☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀LEEME☀☀☀☀☀☀☀☀☀☀☀☀☀☀☀

// -El componente se actualiza con cada render,
// debe ser llamado desde un componente comun como
// la navbar y sera siempre actualizado,
// no renderizar el componente desde otros componentes en 
// simultaneo por que suman cantidades erroneamente

const Cart = (props) => {
  const [order, setOrder] = useState(props.order);
  const [user, setUser] = useState(false);
  const [orderStatus, setOrderStatus] = useState(props.order.status);
  const [loading, setLoading] = useState(false);
  const db = firebase.firestore();
  var storage = firebase.storage();
  var storageRef = storage.ref();
  var eljason = storageRef.child("excel/eljason2.json");

  let allProds = props.allProds
  let productos = props.productos
  let theJson = props.theJson

  useEffect(() => {
    if (!user) {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          db.collection("users")
            .doc(user.uid)
            .get()
            .then(async data => {
           
              let usuario = data.data();
              if(usuario){
                setUser(usuario);
              //Busca ordenes del user y si esta on_cart la setea
              // si no esta on_cart o no existe
              // updatea la order de estado redux con user
              let prevOrder = await cartDB.getUserOrder(data.data().id);
              //si el usuario tiene cart previo y cart en local

              //TUVE QUE MODIFICAR OON_CART PORQUE SINO DUPLICABA TODO EL CARRITO CUANDO IBAS A CHECKOUT!!!!!!!!!!!!!
              if (prevOrder.status === "oon_cart" && orderStatus === "on_cart") {
                console.log("IF: 1")
                let newOrder = order;
                //map de cada item del cart previo
                prevOrder.itemsList.map(prevItem => {
                  let already = false;
                  //2do map de cada item viejo en carrito actual
                  newOrder.itemsList.map(currentItem => {
                    if (prevItem.product.ID === currentItem.product.ID) {
                      currentItem.quantity += prevItem.quantity;
                      already = true;
                    }
                  });
                  //si no estaba se pushea
                  if (!already) {
                    newOrder.itemsList.push(prevItem);
                  }
                });
                newOrder.user = usuario;
                await props.updateOrder(newOrder);
                setItems(newOrder.itemsList);

                //si el usuario tiene cart previo y cart vacio en local
              } else if (
                prevOrder.status === "on_cart" &&
                orderStatus === "created"
              ) {
                console.log("IF: 2")
                console.log(usuario.comision)
                console.log(prevOrder)
                
                if (!productos) getProducts(usuario.comision,prevOrder);
               
              } else if (!prevOrder.exists) {
                console.log("IF: 3")
                //si el usuario no tiene cart previo y cart vacio en local
                let newOrder = order;
                newOrder.user = usuario;
                props.updateOrder(newOrder);
                setOrder(newOrder);
              }
              }
              
            });
        } else {
          console.log("No user!");
        }
      });
    }
  },[]);

  //setItems recibe un array de items y los formatea para la table
  let setItems = arr => {
    let itemsDisplay = [];
    arr.map(item => {
      return itemsDisplay.push({
        id: item.product.ID,
        codigo: item.product.CODIGO,
        fabrica: item.product.FABRICA,
        descripcion: item.product.DESCRIPCION,
        aplicacion: item.product.APLICACION,
        precio: item.product.PF,
        add: (
          <Icon
            className={style.cart}
            onClick={() => {
              addItem(item.product.ID);
            }}
            icon={faPlus}
          />
        ),
        cantidad: item.quantity,
        minus: (
          <Icon
            className={style.cart}
            onClick={() => {
              minusItem(item.product.ID);
            }}
            icon={faMinus}
          />
        ),
        subtotal: item.subtotal,
        delete: (
          <Icon
            className={style.cart}
            onClick={() => {
              deleteItem(item.product.ID);
            }}
            icon={faTrashAlt}
          />
        )
      });
    });
    return itemsDisplay;
  };

  useEffect(() => {
    setItems(props.itemsList);
  }, [order]);

  const getProducts = (comision,prevOrder) => {
    let products = [];
    eljason
      .getDownloadURL()
      .then(function(url) {
        //console.log(url);
        Axios({
          method: "get",
          url: url,
          responseType: "blob"
        }).then(function(response) {
          const fr = new FileReader();
          fr.addEventListener("load", e => {
            let prods = JSON.parse(fr.result);
            theJson(prods);
            if (comision) {
              prods.map((producto, index) => {
                return products.push({
                  clickToSelect: true,
                  id: producto.ID,
                  codigo: producto.CODIGO,
                  fabrica: producto.FABRICA,
                  keys: producto.KEYS,
                  descripcion: producto.DESCRIPCION,
                  aplicacion: producto.APLICACION,
                  PL: producto.PL.toFixed(2)
                  ,
                  PF: (producto.PL - (producto.PL * comision) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
                  agregarFueraFormatter: (
                    <Icon
                      color="red"
                      icon={faCartPlus}
                      onClick={() => {
                        console.log(
                          "este es el producto desde afuera de formatter",
                          producto
                        );
                        console.log(
                          "este es la order desde afuera de formatter",
                          order
                        );
                      }}
                    />
                  )
                });
              });
              let orderSubtotal = 0
              if(prevOrder.itemsList.length){
                console.log("Products!!",products)
                prevOrder.itemsList.map(elem=>{
                  products.map(prod=>{
                    if(prod.id === elem.product.ID){
                      console.log("Cart",elem.product.PF)
                      console.log("New",Number(prod.PF))
                      console.log("Quantity",elem.quantity)
                      elem.product.PF = Number(prod.PF)
                      elem.subtotal = Number(prod.PF)*elem.quantity
                      elem.product.subtotal = Number(prod.PF)*elem.quantity
                      orderSubtotal = orderSubtotal + elem.subtotal
                    }
                  })
                })
              }
              // console.log("OrderSubtotal",orderSubtotal)
              // console.log(orderSubtotal.toString().replace(
              //   /(\d)(?=(\d{3})+(?!\d))/g,
              //   "$1,"
              // ))
              prevOrder.orderSubtotal = orderSubtotal
              prevOrder.total = orderSubtotal.toString().replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              )
              props.updateOrder(prevOrder);
            } else {
              prods.map((producto, index) => {
                return products.push({
                  clickToSelect: true,
                  id: producto.ID,
                  codigo: producto.CODIGO,
                  fabrica: producto.FABRICA,
                  keys: producto.KEYS,
                  descripcion: producto.DESCRIPCION,
                  aplicacion: producto.APLICACION,
                  PL: producto.PL.toString().replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                });
              });
            }
            allProds(products);
            setLoading(false);
          });
          fr.readAsText(response.data);
        });
      })
      .catch(function(error) {
        switch (error.code) {
          case "storage/object-not-found":
            break;
          case "storage/unauthorized":
            break;
          case "storage/canceled":
            break;
          case "storage/unknown":
            break;
        }
      });
  };

  //addItem copia la  orden del estado a una variable,
  //itera sobre itemsList de la copia y suma al id
  // llama a la accion de updateOrder con el carrito nuevo
  // no funciona para productos nuevos pero puede ir adentro de addNewItem ojota
  let addItem = itemId => {
    let newOrder = props.order;
    let newItemArr = [];
    newOrder.itemsList.map(item => {
      if (item.product.ID === itemId) {
        item.quantity += 1;
        item.subtotal = item.product.PF * item.quantity;
      }
      newItemArr.push(item);
    });
    newOrder.itemsList = newItemArr;
    setOrder(newOrder);
    setItems(newItemArr);
    props.updateOrder(newOrder);
  };

  // let addNewItem = es una acción de redux para agregar
  //un item al carrito desde cualquier componente.

  //minusItem copia la orden del estado a una variable,
  //itera sobre itemsList de la copia y resta al id
  // si resta a 0 NO pushea al nuevo array
  // sobreescribe el array de items en la newOrder
  // llama a la accion de updateOrder con el carrito nuevo
  let minusItem = itemId => {
    let newOrder = props.order;
    let newItemArr = [];
    newOrder.itemsList.map(item => {
      if (item.quantity === 0) return console.log("la corte");
      if (item.product.ID === itemId) {
        item.quantity -= 1;
        item.subtotal = item.product.PF * item.quantity;
      }
      if (item.quantity >= 1) {
        newItemArr.push(item);
      }
    });
    newOrder.itemsList = newItemArr;
    // setea order e items con el newitemarr
    setOrder(newOrder);
    setItems(newItemArr);
    //update a la orden en redux
    console.log("UPDATEORDERRRRRRRRRRRRR")
    props.updateOrder(newOrder);
  };

  //delete item copia la orden del estado a una variable,
  //itera sobre itemsList de la copia y no pushea el id a newItemArr
  // sobreescribe el array de items en la newOrder
  // llama a la accion de updateOrder con la orden nueva
  let deleteItem = async itemId => {
    let newOrder = props.order;
    let newItemArr = [];
    newOrder.itemsList.map(item => {
      if (item.product.ID === itemId) {
      } else {
        newItemArr.push(item);
      }
    });
    newOrder.itemsList = [];
    newOrder.itemsList = newItemArr;

 
    setLoading(true);
    //update a la orden en redux
    await props.deleteItem(newOrder).then(() => {
      // setea order e items con el newitemarr
      // setOrder(newOrder);
      // setItems(newOrder.itemsList);
      setLoading(false);
    });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {props.order.status === "created" || props.order.itemsList <= 0 ? (
        <div className={style.emptycartcont}>
          <div className={style.emptycartimg}>
            <img src={EmptyCartImg} alt="Tu carrito esta vacio" />
          </div>
          <p
            onClick={() => {
              props.onHide();
              props.history.push("/catalogo");
            }}
            className={style.mutedtext}
          >
            Tu carrito esta vacio, agregá productos desde el{" "}
            <Link to="/catalogo">catalogo</Link>
          </p>
        </div>
      ) : (
        <div className={style.container}>
          <div className={style.header}>
            <p className={style.text}>
              {" "}
              Orden: <div className={style.value}>{props.order.id}</div>
            </p>
            {loading ? (
              <Icon icon={faCircleNotch} spin color="#74C11C" size="2x" />
            ) : (
              <img
                style={{ height: "25px", marginTop: "20px" }}
                src={logoText}
                alt="rapipartes"
              />
            )}
          </div>
          {props.order.status === "on_cart" ? (
            loading ? (
              <p className={style.textred}>
                {" "}
                <Icon size="sm" icon={faDotCircle} spin /> Actualizando
              </p>
            ) : (
              <p className={style.textgreen}>
                {" "}
                <Icon size="sm" icon={faDotCircle} /> En carrito
              </p>
            )
          ) : (
            <p>Creada</p>
          )}

          <>
            <BootstrapTable
              keyField="id"
              hover
              striped
              bordered={false}
              data={setItems(props.itemsList)}
              columns={tableColumns}
              //pagination={paginationFactory(tableOptions)}
            />
            <hr />
            <div className={style.totalcont}>
              <p className={style.text}>
                Subtotal:
                <p className={style.value}>
                  {props.order.orderSubtotal
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </p>
              </p>
              <hr />
              {props.order.discount > 0 ? (
                <>
                  <p className={style.text}>
                    DESCUENTO:{props.order.discount}%
                  </p>
                  <hr />
                </>
              ) : null}

              <p className={style.text}>
                Total:
                <p className={style.total}>{props.order.total}</p>
              </p>
              <hr />
            </div>
          </>

          <Button
            onClick={() => {
              props.onHide();
              props.history.push("/checkout");
            }}
          >
            Realizar pedido
          </Button>

          <Link
            style={{ textAlign: "center", marginTop: "15px" }}
            onClick={() => {
              props.onHide();
              props.history.push("/catalogo");
            }}
            to="/catalogo"
          >
            Seguir comprando
          </Link>
        </div>
      )}
    </Modal>
  );
};

const mapDispatchToProps = function(dispatch, ownprops) {
  return {
    updateOrder: order => dispatch(updateOrder(order)),
    deleteItem: order => dispatch(deleteItem(order)),
    allProds: prods => dispatch(saveProducts(prods)),
    addNewItem: (itemId, order) => dispatch(addNewItem(itemId, order)),
    theJson: eljson => dispatch(saveJson(eljson))
  };
};

const mapStateToProps = function(state) {
  return {
    state,
    order: state.cart,
    itemsList: state.cart.itemsList,
    productos: state.jsonData.data,
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));
